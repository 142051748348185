import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import {createStyles, Grid, TextField} from "@material-ui/core";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CalendarTodayOutlined} from "@material-ui/icons";
import {WorkshopContext} from "../views/LaunchWorkshop";


const useStyles = makeStyles(() =>
    createStyles({
        paper: {
            padding: 10
        }
    })
);

const DatePickerFormat = "MM/DD/YYYY"

export const EditableWorkshopInfo = () => {
    const classes = useStyles()

    const workshopCtx = useContext(WorkshopContext)

    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Grid container justify="flex-start" alignItems={"center"}>
                {/*<Grid container item justify={"flex-start"} alignItems={"center"} spacing={1} sm={12} md={2}>*/}
                <Grid container item className={"pr-5"} sm={12} md={2}>
                    {/*<Grid item>*/}
                    {/*    <CalendarTodayOutlined/>*/}
                    {/*</Grid>*/}
                    <DatePicker
                        format={DatePickerFormat}
                        margin="normal"
                        id="date-picker-start-date"
                        label="Start date"
                        maxDate={workshopCtx?.endDate || new Date("2100-01-01")}
                        value={workshopCtx?.startDate || null}
                        onChange={(date) => {
                            if (date) {
                                workshopCtx?.setStartDate(date.toISOString())
                            }
                        }}
                    />
                </Grid>
                <Grid container item className={"pr-5"} sm={12} md={2}>
                    {/*<Grid item>*/}
                    {/*    <CalendarTodayOutlined/>*/}
                    {/*</Grid>*/}
                    <DatePicker
                        format={DatePickerFormat}
                        margin="normal"
                        id="date-picker-end-date"
                        label="End date"
                        minDate={workshopCtx?.startDate || new Date("1900-01-01")}
                        value={workshopCtx?.endDate || null}
                        onChange={(date) => {
                            if (date) {
                                workshopCtx?.setEndDate(date.toISOString())
                            }
                        }}
                    />
                </Grid>
                <Grid container item className={"pr-5"} sm={12} md={8}>
                    <TextField
                        id="workshop-name"
                       label="Enter a workshop name"
                       className={"w-1/2"}
                       onChange={(e) => {
                           workshopCtx?.setWorkshopName(e.target.value)
                       }}
                       value={workshopCtx?.workshopName}
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default EditableWorkshopInfo