import React, {Suspense} from 'react';
import './App.css'
import './assets/css/_tailwind.build.css';
import {Router, Switch, RouteProps, Route} from 'react-router-dom'
import { createBrowserHistory } from "history"
import Error404 from "./views/Error404";
import {CustomWaiting} from "./components/CustomWaiting";
import LaunchHome from "./views/LaunchHome";
import CalendarView from "./views/CalendarView";
import Curriculum from "./views/Curriculum";
import DetailedView from "./views/DetailedView";
import EvaluationView from "./views/EvaluationView";
import HowItWorks from "./views/HowItWorks";
import LaunchWorkshop from "./views/LaunchWorkshop";
import MyWorkshop from "./views/MyWorkshop";
import ParticipantList from "./views/ParticipantList";
import SelfReflection from "./views/SelfReflection";
import UnitPage from "./views/UnitPage";
import SubmittedWorkshop from "./views/SubmittedWorkshop";
import WorkshopSubmissions from "./views/WorkshopSubmissions";
import { StandardLayout } from './components/layout/StandardLayout';


export const xhistory = createBrowserHistory({ basename: "" })

interface XRouteProps extends Omit<RouteProps, "component"> {
  fullLayout?: boolean,
  component: React.ElementType
}

const CustomRoute = ({component: TheComponent, fullLayout, ...rest}: XRouteProps) => {
    return (
        <Route {...rest}
            render={(props) =>
                fullLayout ? (
                    <Suspense fallback={<CustomWaiting/>}>
                        <TheComponent {...props} />
                    </Suspense>
                ) : (
                    <StandardLayout>
                        <Suspense fallback={<CustomWaiting/>}>
                            <TheComponent {...props} />
                        </Suspense>
                    </StandardLayout>
                )
            }
        />
    )
}

const App = () => {
    return (
        <Router history={xhistory}>
            <Switch>
                <CustomRoute exact path="/" component={LaunchHome}/>
                <CustomRoute path="/calendar-view/:workshop" component={CalendarView}/>
                <CustomRoute path="/detailed-view/:workshop" component={DetailedView}/>
                <CustomRoute path="/curriculum" component={Curriculum}/>
                <CustomRoute path="/unit/:unit" component={UnitPage}/>
                <CustomRoute path="/evaluation-view/:group/:submission" component={EvaluationView}/>
                <CustomRoute path="/how-it-works" component={HowItWorks}/>
                <CustomRoute path="/launch-workshop" component={LaunchWorkshop}/>
                <CustomRoute path="/my-workshop/:workshop/:unit/:eventId" component={MyWorkshop}/>
                <CustomRoute path="/submitted-workshop/:workshop/:submission" component={SubmittedWorkshop}/>
                <CustomRoute path="/workshop-submissions/:workshop/:unit/:eventId" component={WorkshopSubmissions}/>
                <CustomRoute path="/participant-list/:workshop" component={ParticipantList}/>
                <CustomRoute path="/self-reflection" component={SelfReflection}/>
                <CustomRoute fullLayout component={Error404}/>
            </Switch>
        </Router>
    );
}

export default App;