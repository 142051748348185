import {Submission} from "../graphql/generated/graphql-main";
import cfg from "../config/general-config";
import {useRouteMatch} from "react-router-dom";

const api = cfg.api
export const apiUrls = {
    gql: () => {
        if (api.portless) {
            return `${cfg.protocol}://${api.host}/${api.gqlPath}`
        } else {
            return `${cfg.protocol}://${api.host}:${api.port}/${api.gqlPath}`
        }
    },
    rest: () => {
        if (api.portless) {
            return `${cfg.protocol}://${api.host}`
        } else {
            return `${cfg.protocol}://${api.host}:${api.port}`
        }
    }
}

/**
 * Great helper function to allow type-checking of object keys in places
 * where using direct type is NOT allowed (e.g. Mongo `sort`)
 */
export const nameof = <T>(key: keyof T): keyof T => key

export const dateTimeFormat: string = "MMM DD, YYYY - HH:mm"
export const zeroUnit: any = {num: 0, title: "", objectives: [""], duration: ""}

/**
 * NOTE: ONLY needed when parsing GQL response into object (casting) and then modifying
 *  the object, then saving it. GQL response includes `__typename` which will automatically
 *  be inserted when pushing back, but __typename will be rejected by GQL, so strip it.
 *  In all other cases where we create JS/TS object from zero, NO need for typename stripping.
 *
 * @param state
 */
export const prepForPush = (state: any) => {
    let tmp = Object.assign({}, state)
    return stripTypename(tmp)
}

/**
 * All Apollo Client query responses include the `__typename` field, but when returning data
 * to a mutation to update a state in the database, we CANNOT include the `__typename` because the
 * Graph schema is NOT defined to include it, so we have to strip it
 *
 * @sources
 *  - https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
 * @param state
 */
export const stripTypename = (state: any) => {
    let tmp = JSON.stringify(
        state,
        (key, value) => (key === '__typename' ? undefined : value)
    )
    return JSON.parse(tmp)
}

/**
 * React Hook: Extract active router path
 */
export function useActivePath() {
    const match = useRouteMatch()
    return match.url
}

export const isUnitEvaluated = (submissions: Submission[]) => {
    let flag = true
    submissions?.forEach((s) => {
        if (!s?.evaluated) flag = false
    })
    return flag
}

export const isParticipantPage = (path: string) => {
    return (path.startsWith("/my-workshop")
        || path.startsWith("/submitted-workshop") || path.startsWith("/workshop-submissions"))
}