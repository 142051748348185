import React, {useEffect, useState} from 'react';
import List from "@material-ui/core/List";
import {Chip, Collapse} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Link, useRouteMatch} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {
    Query,
    QuerySubmissionsByParticipantArgs,
    QuerySubmissionsByUnitArgs,
    QueryWorkshopsArgs,
    QueryWorkshopUnitsArgs,
    Submission,
    Unit,
    Workshop
} from "../../graphql/generated/graphql-main";
import {mainGqlTemplates} from "../../utils/gql-client";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/root-reducer";
import {useParams} from "react-router";
import {menuActions} from "../../store/menu";
import {useAuth} from "../auth/AuthProvider";
import {CustomWaiting} from "../CustomWaiting";
import {isParticipantPage, isUnitEvaluated} from '../../utils';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        nested_2: {
            paddingLeft: theme.spacing(8),
        },
        nested_3: {
            paddingLeft: theme.spacing(12),
        },
        nested_4: {
            paddingLeft: theme.spacing(16),
        },
        evaluated: {
            backgroundColor: "#5fbd4b",
            height: "15px",
            width: "15px"
        },
        toBeEvaluated: {
            backgroundColor: "#F9C813",
            height: "15px",
            width: "15px"
        },
        selectedMenuItem: {
            backgroundColor: "#4dabf5",
        }
    }))

/**
 *
 * @constructor
 */
export const LeftMenu = () => {
    const classes = useStyles()
    const auth = useAuth()
    const dispatch = useDispatch()

    const activeMenuItem = useSelector((state: RootState) => state.menu.activeMenuItem)
    const openMenuItems = useSelector((state: RootState) => state.menu.openMenuItems)

    const thePath = useRouteMatch().url

    const collapse = (menuItem: string) => {
        let copyOpenMenuItems = [...openMenuItems]
        copyOpenMenuItems.includes(menuItem)
            ? copyOpenMenuItems.splice(copyOpenMenuItems.indexOf(menuItem), 1)
            : copyOpenMenuItems.push(menuItem)
        dispatch(menuActions.setOpenMenuItems(copyOpenMenuItems))
    }


    const EvaluationUnitSubmenu = (props: EvaluationUnitProps) => {
        const {data, loading} = useQuery<Query, QuerySubmissionsByUnitArgs>(mainGqlTemplates.GET_SUBMISSIONS_BY_UNIT, {
            variables: {
                launch_code: props.launchCode,
                event_id: props.unit.event_id as string
            },
            fetchPolicy: "cache-and-network"
        })

        return (
            <React.Fragment>
                <ListItem
                    key={`workshopSubmenu-evaluationView-unitSubmenu-${props.unit.event_id}`}
                    button className={classes.nested_3}
                    onClick={() => {
                        collapse(`evaluation-view-${props.launchCode}-${props.unit.num}-${props.unit.event_id}`)
                    }}
                >
                    <ListItemText
                        primary={`Unit ${props.unit.num} (${dayjs(props.unit.start_date as string).format("MMM DD")})`}/>
                    <Chip
                        className={isUnitEvaluated(data?.submissionsByUnit as Submission[])
                            ? classes.evaluated
                            : classes.toBeEvaluated}
                        // label={isUnitEvaluated(data?.submissionsByUnit as Submission[]) ? "Done" : "To do"}
                    />
                    {
                        openMenuItems.includes(`evaluation-view-${props.launchCode}-${props.unit.num}-${props.unit.event_id}`)
                            ? <ExpandLess/> : <ExpandMore/>
                    }
                </ListItem>
                <Collapse
                    in={openMenuItems.includes(`evaluation-view-${props.launchCode}-${props.unit.num}-${props.unit.event_id}`)}
                    timeout="auto" unmountOnExit
                >
                    <List component="div" disablePadding>
                        {loading && <CustomWaiting/>}
                        {!loading && data?.submissionsByUnit?.map((s, i) => {
                            return (
                                <ListItem
                                    key={`workshopSubmenu-evaluationView-unitSubmenu-${s?.id}`}
                                    button
                                    className={classes.nested_4}
                                    component={Link}
                                    to={`/evaluation-view/${i}/${s?.id}`}
                                    onClick={() => {
                                        dispatch(menuActions.setActiveMenuItem(`evaluation-view-${s?.id}`))
                                    }}
                                    selected={activeMenuItem === `evaluation-view-${s?.id}`}
                                >
                                    <ListItemText primary={`G${i}`}/>
                                    <Chip
                                        className={s?.evaluated
                                            ? classes.evaluated
                                            : classes.toBeEvaluated}
                                        // label={s?.evaluated ? "Done" : "To do"}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }

    const WorkshopSubmenu = (props: XProps) => {
        const {
            data: workshopData,
            loading: workshopLoading
        } = useQuery<Query, QueryWorkshopUnitsArgs>(mainGqlTemplates.WORKSHOP_UNITS, {
            variables: {
                launch_code: props.workshop.launch_code
            },
            fetchPolicy: "cache-and-network"
        })

        const {
            data: submissionData,
            loading: submissionLoading
        } = useQuery<Query, QuerySubmissionsByParticipantArgs>(mainGqlTemplates.SUBMISSIONS_BY_PARTICIPANT, {
            variables: {
                launch_code: props.workshop.launch_code
            },
            fetchPolicy: "cache-and-network"
        })

        if (workshopLoading || submissionLoading)
            return <ListItem><CustomWaiting/></ListItem>

        return (
            <React.Fragment>
                <ListItem
                    key={"workshopSubmenu"}
                    button
                    className={classes.nested}
                    onClick={() => {
                        collapse(`my-workshop-${props.workshop.launch_code}`)
                    }}
                >
                    {/*<ListItemIcon><CalendarTodayOutlined/></ListItemIcon>*/}
                    <ListItemText primary={dayjs(props.workshop.start_date || undefined).format("MMM DD, YYYY")}/>
                    {openMenuItems.includes(`my-workshop-${props.workshop.launch_code}`)
                        ? <ExpandLess/>
                        : <ExpandMore/>
                    }
                </ListItem>
                <Collapse
                    in={openMenuItems.includes(`my-workshop-${props.workshop.launch_code}`)}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItem
                            key={"workshopSubmenu-calendarView"}
                            button
                            className={classes.nested_2}
                            component={Link}
                            to={`/calendar-view/${props.workshop.launch_code}`}
                            onClick={() => {
                                dispatch(menuActions.setActiveMenuItem(`calendar-view-${props.workshop.launch_code}`))
                            }}
                            selected={activeMenuItem === `calendar-view-${props.workshop.launch_code}`}
                        >
                            <ListItemText primary="Calendar view"/>
                        </ListItem>
                        <ListItem
                            key={"workshopSubmenu-detailedView"}
                            button
                            className={classes.nested_2}
                            component={Link}
                            to={`/detailed-view/${props.workshop.launch_code}`}
                            onClick={() => {
                                dispatch(menuActions.setActiveMenuItem(`detailed-view-${props.workshop.launch_code}`))
                            }}
                            selected={activeMenuItem === `detailed-view-${props.workshop.launch_code}`}
                        >
                            <ListItemText primary="Detailed view"/>
                        </ListItem>
                        <List>
                            <ListItem
                                key={"workshopSubmenu-evaluationView"}
                                button
                                className={classes.nested_2}
                                onClick={() => {
                                    collapse(`evaluation-view-${props.workshop.launch_code}`)
                                }}
                            >
                                <ListItemText primary="Evaluations"/>
                                {openMenuItems.includes(`evaluation-view-${props.workshop.launch_code}`)
                                    ? <ExpandLess/>
                                    : <ExpandMore/>
                                }
                            </ListItem>
                            <Collapse
                                in={openMenuItems.includes(`evaluation-view-${props.workshop.launch_code}`)}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    {workshopData?.workshopUnits?.map((u) => {
                                        return (
                                            <EvaluationUnitSubmenu
                                                key={`workshopSubmenu-evaluationView-${u?.event_id}`}
                                                launchCode={props.workshop.launch_code}
                                                unit={u as Unit}
                                            />
                                        )
                                    })}
                                </List>
                            </Collapse>
                        </List>
                        <ListItem
                            key={"workshopSubmenu-participantList"}
                            button
                            className={classes.nested_2}
                            onClick={() => {
                                collapse(`participant-list-${props.workshop.launch_code}`)
                            }}
                        >
                            <ListItemText primary="Participants"/>
                            {openMenuItems.includes(`participant-list-${props.workshop.launch_code}`)
                                ? <ExpandLess/>
                                : <ExpandMore/>
                            }
                        </ListItem>
                        <Collapse
                            in={openMenuItems.includes(`participant-list-${props.workshop.launch_code}`)}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                {submissionData?.submissionsByParticipant?.map((s) => {
                                    return (
                                        <ListItem
                                            key={`workshopSubmenu-participantList-${s?.participant_id}`}
                                            button
                                            className={classes.nested_3}
                                            component={Link}
                                            to={`/participant-list/${props.workshop.launch_code}`}
                                            // to={`/participant-list/${props.workshop.launchCode}/${s?.participantId}`}
                                            onClick={() => {
                                                dispatch(menuActions.setActiveMenuItem(`participant-list-${s?.participant_id}`))
                                            }}
                                            selected={activeMenuItem === `participant-list-${s?.participant_id}`}
                                        >
                                            <ListItemText primary={s?.name}/>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Collapse>
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }

    const CurriculumMenu = () => {
        const {data, loading} = useQuery<Query, Unit[]>(mainGqlTemplates.GET_UNITS)

        return (
            <React.Fragment>
                <List
                    component="nav"
                    className={classes.root}
                >
                    <ListItem key={"curriculumMenu"} button onClick={() => {
                        collapse("curriculum")
                    }}>
                        <ListItemText primary="Curriculum"/>
                        {openMenuItems.includes("curriculum") ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={openMenuItems.includes("curriculum")} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {loading && (
                                <ListItem key={"curriculumMenu-spinner"} className={classes.nested}>
                                    <CustomWaiting/>
                                </ListItem>
                            )}
                            {!loading && <React.Fragment>
                                <ListItem button className={classes.nested} component={Link} to={"/curriculum"}
                                          onClick={() => {
                                              dispatch(menuActions.setActiveMenuItem("overview"))
                                          }}
                                          selected={activeMenuItem === "overview"}
                                >
                                    <ListItemText primary={"Overview"}/>
                                </ListItem>
                                {data?.units?.map((u) => {
                                    return (
                                        <ListItem key={`curriculumMenu-${u?.num}`} button className={classes.nested}
                                                  component={Link}
                                                  to={`/unit/${u.num}`}
                                                  onClick={() => {
                                                      dispatch(menuActions.setActiveMenuItem(`curriculum-unit-${u.num}`))
                                                  }}
                                                  selected={activeMenuItem === `curriculum-unit-${u.num}`}
                                        >
                                            <ListItemText primary={`Unit ${u.num} - ${u.title}`}/>
                                        </ListItem>
                                    )
                                })}
                            </React.Fragment>}
                        </List>
                    </Collapse>
                </List>
            </React.Fragment>
        )
    }

    const MyWorkshopsMenu = () => {
        const {data, loading} = useQuery<Query, QueryWorkshopsArgs>(mainGqlTemplates.GET_WORKSHOPS, {
            variables: {username: auth.user?.username as string},
            fetchPolicy: "cache-and-network"
        })

        return (
            <React.Fragment>
                <List
                    component={"nav"}
                    className={classes.root}
                >
                    <ListItem
                        key={"myWorkshopsMenu"}
                        button
                        onClick={() => {
                            collapse("my-workshops")
                        }}
                    >
                        <ListItemText primary="My workshops"/>
                        {openMenuItems.includes("my-workshops")
                            ? <ExpandLess/>
                            : <ExpandMore/>
                        }
                    </ListItem>
                    <Collapse in={openMenuItems.includes("my-workshops")} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {loading && <ListItem><CustomWaiting/></ListItem>}
                            {!loading && data?.workshops?.map((workshop) => {
                                return (
                                    <WorkshopSubmenu
                                        key={`myWorkshopsMenu-${workshop?.workshop_id}`}
                                        workshop={workshop as Workshop}
                                    />
                                )
                            })}
                            {data?.workshops?.length === 0 && (
                                <ListItem className={classes.nested}>
                                    <ListItemText primary={"No workshop yet"} className={"italic text-gray-600"}/>
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                </List>
            </React.Fragment>
        )
    }

    /**
     * Main menu for PARTICIPANTS
     * @constructor
     */
    const ParticipantMenu = () => {
        const {workshop: workshopParam} = useParams<{ workshop: string }>();

        const [open, setOpen] = useState<boolean>(true)
        const {data, loading} = useQuery<Query, QueryWorkshopUnitsArgs>(mainGqlTemplates.WORKSHOP_UNITS, {
            variables: {
                launch_code: workshopParam
            }
        })

        return (
            <React.Fragment>
                <List
                    component="nav"
                    className={classes.root}
                >
                    <ListItem key={"participantMenu"} button onClick={() => {
                        setOpen(!open)
                    }}>
                        <ListItemText primary="My workshop"/>
                        {open ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {loading && <ListItem><CustomWaiting/></ListItem>}
                            {!loading && data?.workshopUnits?.map((unit) => {
                                return (
                                    <ListItem key={`participantMenu-${unit?.event_id}`} button
                                              className={classes.nested}
                                              component={Link}
                                              to={`/${unit?.closed ? "workshop-submissions" : "my-workshop"}/${workshopParam}/${unit?.num}/${unit?.event_id}`}
                                              onClick={() => {
                                                  dispatch(menuActions.setActiveMenuItem(`participant-my-workshop-${workshopParam}-${unit?.num}-${unit?.event_id}`))
                                              }}
                                              selected={activeMenuItem === `participant-my-workshop-${workshopParam}-${unit?.num}-${unit?.event_id}`}
                                    >
                                        <ListItemText
                                            primary={`Unit ${unit?.num} (${dayjs(unit?.start_date as string).format("MMM DD")})`}
                                            secondary={unit?.title}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Collapse>
                </List>
            </React.Fragment>
        )
    }

    /**
     * Main menu for ORGANIZERS
     * @constructor
     */
    const OrganizerMenu = () => {
        return (
            <React.Fragment>
                <MyWorkshopsMenu/>

                <Divider/>

                <List
                    component="nav"
                    className={classes.root}
                >
                    <ListItem
                        key={"selfReflectionMenu"}
                        button
                        component={Link}
                        to={'/self-reflection'}
                        onClick={() => {
                            dispatch(menuActions.setActiveMenuItem("self-reflection"))
                        }}
                        selected={activeMenuItem === "self-reflection"}
                    >
                        <ListItemText primary="Self-reflections"/>
                    </ListItem>
                </List>
                <Divider/>

                <CurriculumMenu/>
                <Divider/>

                <List component={"nav"}
                      className={classes.root}
                >
                    <ListItem key={"launchWorkshopMenu"} button component={Link} to={"/launch-workshop"}
                              onClick={() => {
                                  dispatch(menuActions.setActiveMenuItem("launch-workshop"))
                              }}
                              selected={activeMenuItem === "launch-workshop"}
                    >
                        <ListItemText primary="Launch a workshop"/>
                    </ListItem>
                </List>
                <Divider/>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {isParticipantPage(thePath) && <ParticipantMenu/>}
            {(!isParticipantPage(thePath) && auth.isAuthenticated) && <OrganizerMenu/>}
        </React.Fragment>
    )
}

interface XProps {
    workshop: Workshop
}

interface EvaluationUnitProps {
    unit: Unit
    launchCode: string
}