import React from "react"
import {
    DialogContentText, Grid, Typography
} from "@material-ui/core"
import {Send} from "@material-ui/icons";
import ClosableDialog from "./ClosableDialog";
import {ButtonAction} from "../../types";


interface XDialogProps {
    onSubmit: () => void
    setOpen: (arg:boolean) => void
    open: boolean
}

const SubmitDialog = (props: XDialogProps) => {
    const actions:ButtonAction[] = [
        {name: "Confirm", variant: "contained", color: "primary", action: () => {
                props.onSubmit()
                props.setOpen(false)
        }}
    ]

    return (
        <ClosableDialog open={props.open} setOpen={props.setOpen} icon={<Send/>} title={"Submit your work"} actions={actions}>
            <DialogContentText>
                <Typography variant={"body1"} align={"center"}>
                    <b>Are you ready to submit your work for this unit?</b>
                </Typography>
                <br/>
                <Typography variant={"body1"} align={"center"}>
                    Once submitted you will no more be able to modify your texts and video.
                </Typography>
                <br/>
                <Typography variant={"body1"} align={"center"}>
                    Please note that the content of your work will be visible to every participant of this workshop once closed by the organizer.
                </Typography>

            </DialogContentText>
        </ClosableDialog>
    )
}

export default SubmitDialog