import * as React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, Card, CardActions, CardContent, Grid, Typography} from "@material-ui/core";
import {mainGqlTemplates} from "../utils/gql-client";
import {Query, Unit} from "../graphql/generated/graphql-main";
import {useQuery} from "@apollo/client";
import {useAuth} from "../components/auth/AuthProvider";
import {Link, Redirect} from "react-router-dom";
import {CustomWaiting} from "../components/CustomWaiting";
import {menuActions} from "../store/menu";
import {useDispatch} from "react-redux";


const useStyles = makeStyles({
    title: {
        fontSize: 14,
    },
});

const Curriculum = () => {
    const classes = useStyles()
    const auth = useAuth()
    const dispatch = useDispatch()

    const {data, loading} = useQuery<Query, Unit[]>(mainGqlTemplates.GET_CURRICULUM)

    if (!auth.isAuthenticated)
        return <Redirect to={"/"}/>

    if (loading)
        return <CustomWaiting/>

    return (
        <React.Fragment>
            <Typography variant={"h4"} component={"h1"} align={"left"}>A Glimpse at the Curriculum</Typography>
            <Typography variant={"body1"} color={"textSecondary"} align={"left"} gutterBottom>Click on one of the units for more information.</Typography>
            <br/>
            <Grid container justify={"flex-start"} spacing={6}>
                {(data?.units as Unit[]).map((unit, i) => {
                    return (
                        <Grid key={`curriculum-unit-${i}`} item xs={12} sm={6} md={4} lg={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant={"h6"} align={"left"} component={"h2"} color={"secondary"} gutterBottom>
                                        <b>Unit {unit.num} -</b> {unit.title}
                                    </Typography>
                                    <Typography className={classes.title} align={"left"} color="textSecondary" gutterBottom>
                                        Duration: {unit.duration}
                                    </Typography>
                                    {unit.objectives.map((item, i) => {
                                        return (
                                            <Typography key={`learning-objective-${i}`} variant="body2" component="p" align={"left"}>
                                                <b>Learning objective {i+1}:</b> {item}
                                            </Typography>
                                        )
                                    })}
                                </CardContent>
                                <CardActions>
                                    <Button size="large" color={"primary"} to={`/unit/${unit.num}`} component={Link}
                                            onClick={() => {
                                                dispatch(menuActions.setActiveMenuItem(`curriculum-unit-${unit.num}`))
                                            }}
                                    >
                                        Learn More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </React.Fragment>
    )
}

export default Curriculum