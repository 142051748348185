import { XNodeEnvType } from "../types";

const env = process.env

export const X_MODE: XNodeEnvType = env.NODE_ENV as XNodeEnvType

// disable all logging in 'production' mode
if (X_MODE === "production") {
    console.info = () => {}
    console.warn = () => {}
    console.log = () => {}
    console.dir = () => {}
    console.debug = () => {}
}

/**
 * Generic configuration for APIs & services
 */
const cfg = {
    protocol: env.REACT_APP_PROTOCOL!,
    webHost: env.REACT_APP_WEB_HOST!,
    requestTimeout: 5000, // milliseconds
    // when behind Nginx proxy, best to use root subdomain because /the-hub/ causes CORS redirect errors;
    // should be api-hub.xyz.tk instead of api.xyz.tk/the-hub/
    api: {
        host: env.REACT_APP_API_HOST!,
        portless: JSON.parse(env.REACT_APP_API_PORTLESS!),
        port: env.REACT_APP_API_PORT!,
        gqlPath: env.REACT_APP_GQL_PATH!,
        // it may be safe to use GraphQL here using cookies to authenticate each query;
        // we don't trust info from client but instead verify
        restPaths: {
            evt1: `events/evt1`,
            evt2: `events/evt2`
        }
    }
};

export default cfg;