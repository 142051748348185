import React, {HTMLAttributes, ReactElement} from "react"
import {
    Button, createStyles,
    Dialog, Grid, IconButton,
    Theme,
    Typography,
    withStyles
} from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import {makeStyles} from "@material-ui/core/styles";
import {ButtonAction} from "../../types";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
);

const DialogTitle = (props:any) => {
    const classes = useStyles()
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...props.other}>
            <Typography variant="h6">{props.children}</Typography>
            {props.onClose ? (
                <IconButton className={classes.closeButton} onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface ClosableDialogProps extends HTMLAttributes<any>{
    open: boolean
    setOpen: (arg:boolean) => void
    icon: ReactElement
    title: string
    actions: ButtonAction[]
    children: ReactElement
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
    modal?: boolean
}

const ClosableDialog = (props: ClosableDialogProps) => {

    return (
        <Dialog open={props.open}
                fullWidth={true}
                maxWidth={props.maxWidth ? props.maxWidth : "xs"}
                onClose={props.modal ? () => {} : () => { props.setOpen(false) }}
        >
            <DialogTitle id="customized-dialog-title" onClose={() => { props.setOpen(false) }}>
                <Grid container justify={"flex-start"} alignItems={"center"}>
                    <Grid item>
                        {props.icon}
                    </Grid>
                    <Grid item>
                        <Typography variant={"h6"}>&nbsp;{props.title}</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                {props.actions.map((button, i) => {
                    return (
                        <Button key={`closable-dialog-action-button-${i}`}
                                onClick={() => { button.action() }}
                                color={button.color}
                                variant={button.variant}
                                startIcon={button.icon}
                                disabled={button.disabled}
                        >
                            {button.name}
                        </Button>
                    )
                })}
            </DialogActions>
        </Dialog>
    )
}

export default ClosableDialog