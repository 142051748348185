import {ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {apiUrls} from "./index";

/**
 * @sources:
 *  - https://www.sitepoint.com/how-to-build-a-web-app-with-graphql-and-react/
 *  - https://www.apollographql.com/docs/react/get-started/
 *  - https://www.apollographql.com/docs/react/get-started/
 *  - https://www.apollographql.com/docs/react/networking/authentication/
 *
 * @type {DefaultClient<unknown>}
 */
const client = new ApolloClient({
    link: ApolloLink.from([
        onError(({graphQLErrors, networkError}) => {
            if (graphQLErrors)
                graphQLErrors.forEach(({message, locations, path}) => {
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    )
                })

            if (networkError)
                console.log(`[Network error]: ${networkError}`)
        }),
        new HttpLink({
            uri: apiUrls.gql(),
            credentials: 'include'
        })
    ]),
    cache: new InMemoryCache({
        addTypename: true // important to keep it
    })
})

export const mainGqlTemplates = {
    IS_AUTHENTICATED: gql`
        query IsAuthenticated {
            authenticated {
                user {
                    id
                    active
                    email
                    insertInstant
                    name
                    username
                    tenantId
                    verified
                    memberships {
                        groupId
                        id
                        name
                    }
                    registrations {
                        id
                        applicationId
                        roles
                    }
                }
                redirect
            }
        }
    `,
    LOGOUT_MUTATION: gql`
        mutation Logout {
            logout
        }
    `,
    GET_MY_REFLECTIONS: gql`
        query ReflectionsByUser($username: String!) {
            reflectionsByUser(username: $username) {
                id
                unit
                date
                summary
                content
            }
        }
    `,
    GET_OTHERS_REFLECTIONS: gql`
        query ReflectionsByUnit($unit_num: Int!) {
            reflectionsByUnit(unit_num: $unit_num) {
                username
                date
                summary
                content
            }
        }
    `,
    GET_UNITS: gql`
        query Units {
            units {
                num
                title
            }
        }
    `,
    GET_CURRICULUM: gql`
        query Units {
            units {
                num
                title
                duration
                objectives
            }
        }
    `,
    UPDATE_REFLECTION: gql`
        mutation UpdateReflection($reflectionInput: ReflectionInput!) {
            updateReflection(reflectionInput: $reflectionInput)
        }
    `,
    GET_REFLECTION: gql`
        query Reflection($reflection_id: ID!) {
            reflection(reflection_id: $reflection_id) {
                unit
                summary
                content
            }
        }
    `,
    DELETE_REFLECTION: gql`
        mutation DeleteReflection($reflection_id: ID!) {
            deleteReflection(reflection_id: $reflection_id)
        }
    `,
    GET_WORKSHOP: gql`
        query Workshop($launch_code: ID!) {
            workshop(launch_code: $launch_code) {
                username
                workshop_id
                launch_code
                start_date
                end_date
                participants {
                    participant_id
                    name
                }
                schedule {
                    event_id
                    unit
                    start_date
                    end_date
                    closed
                }
                name
            }
        }
    `,
    GET_WORKSHOPS: gql`
        query GetWorkshops($username: String!) {
            workshops(username: $username) {
                workshop_id
                launch_code
                start_date
                schedule {
                    unit
                }
            }
        }
    `,
    GET_UNIT: gql`
        query Unit($num: Int!) {
            unit(num: $num) {
                duration
                instructions
                materials
                materials_links
                num
                objectives
                plan
                preparation
                title
                url
            }
        }
    `,
    UPDATE_WORKSHOP: gql`
        mutation UpdateWorkshop($workshopInput: WorkshopInput!) {
            updateWorkshop(workshopInput: $workshopInput)
        }
    `,
    WORKSHOP_UNITS: gql`
        query WorkshopUnits($launch_code: ID!) {
            workshopUnits(launch_code: $launch_code) {
                num
                title
                closed
                start_date
                event_id
            }
        }
    `,
    WORKSHOP_UNIT: gql`
        query WorkshopUnit($launch_code: ID!, $unit_num: Int, $event_id: ID) {
            workshopUnit(launch_code: $launch_code, unit_num: $unit_num, event_id: $event_id) {
                num
                event_id
                title
                url
                start_date
                workshop_name
                participants {
                    participant_id
                    name
                }
                closed
            }
        }
    `,
    GET_SUBMISSION: gql`
        query GetSubmission($submission_id: ID!) {
            submission(submission_id: $submission_id) {
                id
                launch_code
                unit
                date
                participants {
                    participant_id
                    name
                }
                reflection
                questions
                link
                feedback
                event_id
            }
        }
    `,
    GET_SUBMISSIONS_BY_UNIT: gql`
        query SubmissionsByUnit($launch_code: ID!, $event_id: ID!) {
            submissionsByUnit(launch_code: $launch_code, event_id: $event_id) {
                id
                launch_code
                date
                evaluated
                participants {
                    name
                }
            }
        }
    `,
    GET_SUBMISSIONS: gql`
        query Submissions($launch_code: ID!) {
            submissions(launch_code: $launch_code) {
                start_date
                event_id
                unit
                closed
                submissions {
                    date
                    id
                    evaluated
                }
            }
        }
    `,
    UPDATE_SUBMISSION: gql`
        mutation UpdateSubmission($submissionInput: SubmissionInput!) {
            updateSubmission(submissionInput: $submissionInput)
        }    
    `,
    SUBMISSIONS_BY_PARTICIPANT: gql`
        query SubmissionsByParticipant($launch_code: ID!) {
            submissionsByParticipant(launch_code:$launch_code) {
                participant_id
                name
                submissions {
                    id
                    evaluated
                    unit
                }
            }
        }
    `
}

export {
    client as xGqlMain,
}