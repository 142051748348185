import {Grid, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {UnitSelect} from "../UnitSelect";
import {
    Mutation, MutationDeleteReflectionArgs,
    MutationUpdateReflectionArgs,
    Query,
    QueryReflectionArgs,
    Unit
} from "../../graphql/generated/graphql-main";
import {mainGqlTemplates} from "../../utils/gql-client";
import {useMutation, useQuery} from "@apollo/client";
import DeleteDialog from "./DeleteDialog";
import {useAuth} from "../auth/AuthProvider";
import ClosableDialog from "./ClosableDialog";
import EditIcon from "@material-ui/icons/Edit";
import {ButtonAction} from "../../types";
import {CustomWaiting} from "../CustomWaiting";
import {zeroUnit} from "../../utils";


interface EditReflectionDialogProps {
    setOpen: (arg: boolean) => void
    open: boolean
    onSaveOrDelete: () => void
    id: string
    setSuccessSnackbar: (arg: boolean) => void
    setErrorSnackbar: (arg: boolean) => void
    setDeleteSnackbar: (arg: boolean) => void
}

export const EditReflectionDialog = (props: EditReflectionDialogProps) => {
    const auth = useAuth()
    // The UnitSelect component requires a Unit object since it displays both the unit nums and titles.
    // However, since the info is already loaded in the dialog here, only the unit num can be passed for selection.
    // Hence, the other fields can remain empty to avoid an extra GQL query.
    const [unit, setUnit] = useState<Unit>(zeroUnit)
    const [summary, setSummary] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)

    const {data, loading} = useQuery<Query,QueryReflectionArgs>(mainGqlTemplates.GET_REFLECTION, {
        variables: {reflection_id: props.id},
        fetchPolicy: "cache-and-network"
    })

    const [updateReflection] = useMutation<Mutation, MutationUpdateReflectionArgs>(mainGqlTemplates.UPDATE_REFLECTION)
    const [deleteReflection] = useMutation<Mutation, MutationDeleteReflectionArgs>(mainGqlTemplates.DELETE_REFLECTION)

    useEffect(() => {
        if (!data?.reflection)
            return;

        const {unit, summary, content} = data?.reflection
        // The UnitSelect component requires a Unit object since it displays both the unit nums and titles.
        // However, since the info is already loaded in the dialog here, only the unit num can be passed for selection.
        // Hence, the other fields can remain empty to avoid an extra GQL query.
        setUnit({num: unit, title: "", duration: "", objectives: [""]})
        setSummary(summary ? summary : "")
        setContent(content ? content: "")

    }, [data])

    const onSaveReflection = async () => {
        const res = await updateReflection({
            variables: {
                reflectionInput: {
                    id: props.id,
                    username: auth.user?.username as string,
                    unit: unit?.num as number,
                    summary: summary,
                    content: content
                }
            }
        })
        props.setDeleteSnackbar(false)
        if (res?.data?.updateReflection) {
            props.setOpen(false);
            props.setSuccessSnackbar(true)
            props.onSaveOrDelete()
        } else {
            props.setErrorSnackbar(true)
        }
    }

    const onDeleteReflection = async () => {
        const res = await deleteReflection({
            variables: {
                reflection_id: props.id
            }
        })
        props.setDeleteSnackbar(true)
        if (res?.data?.deleteReflection) {
            props.setOpen(false);
            props.setSuccessSnackbar(true)
            props.onSaveOrDelete()
        } else {
            props.setErrorSnackbar(true)
        }
    }

    const actions:ButtonAction[] = []
    if (props.id) {
        actions.push(
            {
                name: "Delete", action: () => {setDeleteDialog(true)}, variant: "contained", color: "secondary"
            }
        )
    }
    actions.push(
        {
            name: "Save", action: onSaveReflection, variant: "contained", color: "primary", disabled: (!unit || summary==="")
        }
    )

    return (
        <React.Fragment>
            <ClosableDialog open={props.open} setOpen={props.setOpen} icon={<EditIcon/>}
                            title={"Edit your reflection"} actions={loading ? [] : actions} maxWidth={"md"} modal={true}
            >
                <React.Fragment>
                    {loading && <CustomWaiting/>}
                    {!loading && <Grid container justify={"flex-start"} spacing={2}>
                        <Grid container item>
                            <UnitSelect selectedUnit={unit} setSelectedUnit={(arg) => setUnit(arg)}/>
                        </Grid>
                        <Grid container item>
                            <TextField id="outlined-basic" label="Summary" variant="outlined" value={summary}
                                       onChange={(e) => {
                                           setSummary(e.target.value)
                                       }}
                                       error={!Boolean(summary)}
                                       helperText={"This field is required."}
                                       required
                                       fullWidth
                            />
                        </Grid>
                        <Grid container item>
                            <TextField
                                id="outlined-multiline-static"
                                label="My reflections"
                                multiline
                                rows={10}
                                variant="outlined"
                                fullWidth
                                value={content}
                                onChange={(e) => {
                                    setContent(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>}
                </React.Fragment>
            </ClosableDialog>
            {deleteDialog &&
            <DeleteDialog onDelete={onDeleteReflection} open={deleteDialog}
                          setOpen={(arg) => setDeleteDialog(arg)}
                          mainMessage={"Are you sure you want to delete this?"}
            />}
        </React.Fragment>
    );
}