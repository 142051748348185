import React from "react"
import {DialogContentText, Typography} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete";
import ClosableDialog from "./ClosableDialog";
import {ButtonAction} from "../../types";


interface DeleteDialogProps {
    onDelete: () => void
    setOpen: (arg:boolean) => void
    open: boolean
    mainMessage: string
    subMessage?: string
}

const DeleteDialog = (props: DeleteDialogProps) => {
    const actions:ButtonAction[] = [
        { name: "Confirm", color: "secondary", variant: "contained", action: () => {
                props.onDelete()
                props.setOpen(false)
        }}
    ]

    return (
        <ClosableDialog open={props.open}
                        setOpen={props.setOpen}
                        icon={<DeleteIcon/>}
                        title="Delete"
                        actions={actions}
        >
            <DialogContentText>
                <Typography variant={"body1"} align={"center"} gutterBottom>
                    <b>{props.mainMessage}</b>
                </Typography>
                <Typography variant={"body1"} align={"center"}>{props.subMessage}</Typography>
            </DialogContentText>
        </ClosableDialog>
    )
}

export default DeleteDialog