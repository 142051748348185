import * as React from 'react';
import {createContext, HTMLAttributes, useContext, useEffect, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {
    FusionAuthRegistration,
    FusionAuthUser,
    Mutation,
    Query,
    UserRole
} from "../../graphql/generated/graphql-main";
import {mainGqlTemplates} from "../../utils/gql-client";


// context creation / init
export const AuthContext = createContext<AuthContextData>({
    isAuthenticated: false,
    user: null,
    role: UserRole.User,
    login: () => {},
    logout: () => {}
})
export const useAuth = () => useContext(AuthContext)

const AuthProvider = (props: Props) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useState<FusionAuthUser | null>(null)
    const [role, setRole] = useState<UserRole>(UserRole.User)

    const {data, refetch:login} = useQuery<Query>(
        mainGqlTemplates.IS_AUTHENTICATED,
        { fetchPolicy: "no-cache" }
    )

    // mutation: logout
    const [logout, {data:logoutData}] =
        useMutation<Mutation>(mainGqlTemplates.LOGOUT_MUTATION)

    const authResults = data?.authenticated

    // https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
    // const firstUpdate = useRef(true)
    useEffect(() => {
        // redirect user to fusionauth login if NOT authenticated
        if (authResults?.redirect && role === UserRole.Organizer) {
            window.location.href = authResults.redirect
        } else if (authResults?.user) {
            const user = authResults.user
            setUser(user)
            setIsAuthenticated(true)

            // todo find way to simplify this (too many checks and ifs and maybes, etc.)
            if (user.registrations?.length! > 0) {
                const registrations = user.registrations as FusionAuthRegistration[]
                const role: UserRole = registrations[0].roles?.length! > 0
                    ? registrations[0].roles![0] as UserRole
                    : UserRole.User
                setRole(role)
            }
        }

        // if LOGOUT was requested, load that link to force logout OAuth
        if (logoutData?.logout) {
            window.location.href = logoutData.logout
        }
    }, [data, isAuthenticated, role, logoutData])

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                role,
                logout: async () => {
                    setIsAuthenticated(false)
                    setUser(null)
                    await logout()
                },
                login: async () => {
                    setRole(UserRole.Organizer)
                    await login()
                }
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}
export default AuthProvider

interface Props extends HTMLAttributes<any> {}

type AuthContextData = {
    isAuthenticated?: boolean
    user: FusionAuthUser | null
    role: UserRole
    logout: () => void
    login: () => void
}