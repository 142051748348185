import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import {ApolloProvider} from "@apollo/client";
import {xGqlMain} from "./utils/gql-client"
import {store} from "./store"
import {Provider} from "react-redux";
import AuthProvider from "./components/auth/AuthProvider";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";

import './index.scss';
import './assets/css/_tailwind.build.min.css';

/**
 * @sources
 *  - https://blog.logrocket.com/3-ways-to-add-custom-fonts-to-your-material-ui-project/
 *  - https://material-ui.com/customization/typography/#self-hosted-fonts
 */
const xTheme = createMuiTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'regular'
        ].join(',')
    }
})

ReactDOM.render(
  <React.StrictMode>
      <ApolloProvider client={xGqlMain}>
          <AuthProvider>
              <Provider store={store}>
                  <ThemeProvider theme={xTheme}>
                      <App/>
                  </ThemeProvider>
              </Provider>
          </AuthProvider>
      </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
