import {useAuth} from "../auth/AuthProvider";
import {Link, useRouteMatch} from "react-router-dom";
import * as React from "react";
import {HTMLAttributes, useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import {Button, Divider, Grid} from "@material-ui/core";
import {isParticipantPage} from "../../utils";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {LaunchIcon} from "../../views/LaunchHome";
import Typography from "@material-ui/core/Typography";
import {menuActions} from "../../store/menu";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {LeftMenu} from "./LeftMenu";

export const drawerWidth = 360;

/**
 *
 * @param props
 * @constructor
 */
export const Navigation = (props: HTMLAttributes<any>) => {
    const classes = useStyles();
    const auth = useAuth()
    const dispatch = useDispatch()
    const thePath = useRouteMatch().url

    const [open, setOpen] = useState<boolean>(true);

    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false)

    const clearActiveMenuItem = () => dispatch(menuActions.setActiveMenuItem(""))

    return (
        <React.Fragment>
            <AppBar position="fixed"
                    className={clsx(classes.appBar)}
            >
                <Toolbar>
                    <div>
                        {(auth.isAuthenticated || isParticipantPage(thePath)) && (
                            <div>
                                <IconButton className={clsx(classes.menuButton, open && classes.hide)}
                                            edge="start"
                                            color="inherit"
                                            onClick={handleDrawerOpen}
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </div>
                        )}
                        <div>
                            <Button size="large" color={"inherit"} startIcon={<LaunchIcon/>} to="/" component={Link}
                                    onClick={clearActiveMenuItem}
                            >
                                <Typography variant={"h5"}>LAUNCH</Typography>
                            </Button>
                        </div>
                    </div>

                    <Grid container justify={"flex-end"} spacing={3}>
                        <Grid item>
                            <Button color={thePath === '/how-it-works' ? "secondary" : "inherit"} to={"/how-it-works"}
                                    component={Link} onClick={clearActiveMenuItem}
                            >
                                How it works
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color={thePath === '/curriculum' ? "secondary" : "inherit"}
                                to={"/curriculum"}
                                component={Link}
                                onClick={() => {
                                    dispatch(menuActions.setActiveMenuItem("overview"))
                                }}
                            >
                                Curriculum
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" href={"mailto:vivek@athabascau.ca"}>
                                Support
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color={thePath === '/account' ? "secondary" : "inherit"}
                                    onClick={() => {
                                        auth.isAuthenticated ? auth.logout() : auth.login()
                                        clearActiveMenuItem()
                                    }}
                            >
                                {auth.isAuthenticated ? "Logout" : "Login"}
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            {/* Left sidebar navigation (openable / closable) */}
            <Drawer
                variant="persistent"
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper
                }}
                anchor="left"
                open={open && (auth.isAuthenticated || isParticipantPage(thePath))} /* replace true with open to allow dynamic handling of the drawer */
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                {/* Our custom menus */}
                <div className={classes.drawerContainer}>
                    <LeftMenu/>
                </div>
            </Drawer>
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            // needed to have 'fixed' menu
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        drawerContainer: {
            overflow: 'auto',
            // this caused horizontal scrollbar in menu
            // minWidth: 500
        },
        toolbar: {
            display: 'flex',
            // alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
    }),
);