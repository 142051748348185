import * as React from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@material-ui/core";
import {mainGqlTemplates} from "../utils/gql-client";
import {Query, QueryUnitArgs} from "../graphql/generated/graphql-main";
import {useQuery} from "@apollo/client";
import {useParams} from "react-router";
import {AccessTime, FiberManualRecordOutlined, OutlinedFlag, Star} from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import {useAuth} from "../components/auth/AuthProvider";
import {Redirect} from "react-router-dom";
import {CustomWaiting} from "../components/CustomWaiting";


// https://stackoverflow.com/questions/9003632/color-picker-utility-color-pipette-in-ubuntu

const UnitPage = () => {
    const auth = useAuth()

    const {unit} = useParams<{ unit: string }>();
    const {data, loading} = useQuery<Query, QueryUnitArgs>(mainGqlTemplates.GET_UNIT, {
        variables: {
            num: Number(unit)
        }
    })

    if (!auth.isAuthenticated)
        return <Redirect to={"/"}/>

    if (loading)
        return <CustomWaiting/>

    return (
        <React.Fragment>
            <Typography variant={"h4"} component={"h1"} align={"left"} gutterBottom>
                Unit {unit}: {data?.unit?.title}
            </Typography>
            <br/>
            <Grid className={"x-curriculum"} container spacing={4}>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card className={"x-card md"}>
                        <CardHeader className={"duration"} title={
                            <Typography variant={"h6"}>
                                Duration
                            </Typography>
                        }/>
                        <CardContent className={"text-left"}>
                            <List>
                                <ListItem>
                                    <ListItemIcon><AccessTime/></ListItemIcon>
                                    <ListItemText primary={data?.unit?.duration}/>
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card className={"x-card md"}>
                        <CardHeader className={"objective"} title={
                            <Typography variant={"h6"}>
                                Learning Objectives
                            </Typography>
                        }/>
                        <CardContent className={"text-left"}>
                            <List>
                                {data?.unit?.objectives.map((item, i) => {
                                    return (
                                        <ListItem key={`unitPage-learning-objective-${i}`}>
                                            <ListItemIcon><FiberManualRecordOutlined/></ListItemIcon>
                                            <ListItemText primary={item}/>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card className={"x-card md"}>
                        <CardHeader className={"materials"} title={
                            <Typography variant={"h6"}>
                                Materials
                            </Typography>
                        }/>
                        <CardContent className={"text-left"}>
                            <List>
                                {data?.unit?.materials?.map((item, i) => {
                                    return (
                                        <ListItem key={`unitPage-materials-${i}`}>
                                            <ListItemIcon><FiberManualRecordOutlined/></ListItemIcon>
                                            <ListItemText primary={item}/>
                                        </ListItem>
                                    )
                                })}
                                {data?.unit?.materials_links?.map((item, i) => {
                                    return (
                                        <ListItem key={`unitPage-materials-link-${i}`}>
                                            <ListItemIcon><LinkIcon/></ListItemIcon>
                                            <Link
                                                className="break-all"
                                                href={item as string}
                                                rel={"noopener"}
                                                target={"_blank"}
                                                variant={"body1"}
                                                color={"primary"}
                                            >
                                                {item}
                                            </Link>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card className={"x-card md"}>
                        <CardHeader className={"preparation"} title={
                            <Typography variant={"h6"}>
                                Preparation
                            </Typography>
                        }/>
                        <CardContent className={"text-left"}>
                            <List>
                                {data?.unit?.preparation?.map((item, i) => {
                                    return (
                                        <ListItem key={`unitPage-preparation-${i}`}>
                                            <ListItemIcon><FiberManualRecordOutlined/></ListItemIcon>
                                            <ListItemText primary={item}/>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={"x-card lg"}>
                        <CardHeader className={"plan"} title={
                            <Typography variant={"h6"}>
                                Suggested Plan
                            </Typography>
                        }/>
                        <CardContent className={"text-left"}>
                            <List>
                                {data?.unit?.plan?.map((item, i) => {
                                    return (
                                        <ListItem key={`unitPage-plan-${i}`}>
                                            <ListItemIcon><OutlinedFlag/></ListItemIcon>
                                            <ListItemText primary={item}/>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={"x-card lg"}>
                        <CardHeader className={"instructions"} title={
                            <Typography variant={"h6"}>
                                Activity Instructions for Students
                            </Typography>
                        }/>
                        <CardContent className={"text-left"}>
                            <Typography align={"left"} variant={"body1"}>{data?.unit?.instructions}</Typography>
                        </CardContent>
                        <CardActions className={"justify-center"}>
                            {data?.unit?.url && <Button variant={"contained"} className={"btn-instructions"}
                                                        size={"large"} href={data?.unit?.url} target={"_blank"}
                                                        rel={"noopener"}
                            >
                                Go to the activity
                            </Button>}
                        </CardActions>
                    </Card>
                </Grid>
                {data?.unit?.other_resources && <Grid item xs={12}>
                    <Card className={"x-card"}>
                        <CardHeader className={"otherResources"} title={
                            <Typography variant={"h6"}>
                                Additional Resources
                            </Typography>
                        }/>
                        <CardContent className={"text-left"}>
                            <List>
                                <ListItem>
                                    <ListItemIcon><Star/></ListItemIcon>
                                    <ListItemText primary="Single-line item"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Star/></ListItemIcon>
                                    <ListItemText primary="Single-line item"/>
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>}
            </Grid>

        </React.Fragment>
    )
}

export default UnitPage