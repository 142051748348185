import React, {HTMLAttributes, useState} from "react"
import {DialogContentText} from "@material-ui/core"
import {LaunchIcon} from "../../views/LaunchHome";
import {Unit} from "../../graphql/generated/graphql-main";
import {UnitSelect} from "../UnitSelect";
import dayjs from "dayjs";
import ClosableDialog from "./ClosableDialog";
import {ButtonAction} from "../../types";
import {zeroUnit} from "../../utils";

interface AddEventDialogProps extends HTMLAttributes<any> {
    onAdd: (unit: Unit | null, scheduleData: any) => void
    open: boolean
    setOpen: (arg: boolean) => void
    scheduleData: any
}

const AddEventDialog = (props: AddEventDialogProps) => {
    const [selectedUnit, setSelectedUnit] = useState<Unit>(zeroUnit)

    const onAdd = () => {
        props.onAdd(selectedUnit, props.scheduleData)
        setSelectedUnit(zeroUnit)
        props.setOpen(false)
    }

    const actions: ButtonAction[] = [
        {name: "Add", action: onAdd, color: "primary", variant: "contained"}
    ]

    return (
        <ClosableDialog
            open={props.open}
            setOpen={props.setOpen}
            icon={<LaunchIcon/>}
            title={"LAUNCH"}
            actions={actions}
        >
            <React.Fragment>
                <DialogContentText>
                    {props.scheduleData.start
                        ? dayjs(props.scheduleData.start.toDate()).format('MMMM D, YYYY')
                        : "Something is going wrong."}
                </DialogContentText>

                <UnitSelect
                    selectedUnit={selectedUnit}
                    setSelectedUnit={(arg) => {
                        setSelectedUnit(arg)
                    }}
                />
            </React.Fragment>
        </ClosableDialog>
    )
}

export default AddEventDialog