import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Event = {
  __typename?: 'Event';
  event_id: Scalars['ID'];
  unit: Scalars['Int'];
  start_date: Scalars['String'];
  end_date?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
};

export type EventInput = {
  event_id: Scalars['ID'];
  unit: Scalars['Int'];
  start_date: Scalars['String'];
  end_date?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
};

export type FusionAuthGroupMembership = {
  __typename?: 'FusionAuthGroupMembership';
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['String']>;
};

export type FusionAuthRegistration = {
  __typename?: 'FusionAuthRegistration';
  applicationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FusionAuthResponse = {
  __typename?: 'FusionAuthResponse';
  user?: Maybe<FusionAuthUser>;
  redirect?: Maybe<Scalars['String']>;
};

/** Simplified schema definition for FusionAuth user (inspired from Typescript 'User' & 'SecureIdentity' types) */
export type FusionAuthUser = {
  __typename?: 'FusionAuthUser';
  id: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  memberships?: Maybe<Array<Maybe<FusionAuthGroupMembership>>>;
  registrations?: Maybe<Array<Maybe<FusionAuthRegistration>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateWorkshop?: Maybe<Scalars['String']>;
  updateReflection?: Maybe<Scalars['String']>;
  deleteReflection?: Maybe<Scalars['String']>;
  updateSubmission?: Maybe<Scalars['String']>;
  /** Returns a logout link from OAuth (fusionauth) */
  logout?: Maybe<Scalars['String']>;
};


export type MutationUpdateWorkshopArgs = {
  workshopInput: WorkshopInput;
};


export type MutationUpdateReflectionArgs = {
  reflectionInput: ReflectionInput;
};


export type MutationDeleteReflectionArgs = {
  reflection_id: Scalars['ID'];
};


export type MutationUpdateSubmissionArgs = {
  submissionInput: SubmissionInput;
};

export type Participant = {
  __typename?: 'Participant';
  participant_id: Scalars['ID'];
  name: Scalars['String'];
};

export type ParticipantInput = {
  participant_id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  authenticated?: Maybe<FusionAuthResponse>;
  workshop?: Maybe<Workshop>;
  workshops: Array<Maybe<Workshop>>;
  workshopUnit?: Maybe<Unit>;
  workshopUnits: Array<Maybe<Unit>>;
  units: Array<Unit>;
  unit?: Maybe<Unit>;
  reflection?: Maybe<Reflection>;
  reflectionsByUser: Array<Maybe<Reflection>>;
  reflectionsByUnit: Array<Maybe<Reflection>>;
  submission?: Maybe<Submission>;
  submissionsByUnit: Array<Maybe<Submission>>;
  submissions: Array<Maybe<SubmissionSet>>;
  submissionsByParticipant: Array<Maybe<SubmissionSet>>;
};


export type QueryWorkshopArgs = {
  launch_code: Scalars['ID'];
};


export type QueryWorkshopsArgs = {
  username: Scalars['String'];
};


export type QueryWorkshopUnitArgs = {
  launch_code: Scalars['ID'];
  unit_num?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['ID']>;
};


export type QueryWorkshopUnitsArgs = {
  launch_code: Scalars['ID'];
};


export type QueryUnitArgs = {
  num: Scalars['Int'];
};


export type QueryReflectionArgs = {
  reflection_id: Scalars['ID'];
};


export type QueryReflectionsByUserArgs = {
  username: Scalars['String'];
};


export type QueryReflectionsByUnitArgs = {
  unit_num: Scalars['Int'];
};


export type QuerySubmissionArgs = {
  submission_id: Scalars['ID'];
};


export type QuerySubmissionsByUnitArgs = {
  launch_code: Scalars['ID'];
  event_id: Scalars['ID'];
};


export type QuerySubmissionsArgs = {
  launch_code: Scalars['ID'];
};


export type QuerySubmissionsByParticipantArgs = {
  launch_code: Scalars['ID'];
};

export type Reflection = {
  __typename?: 'Reflection';
  id: Scalars['ID'];
  username: Scalars['String'];
  unit: Scalars['Int'];
  summary?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type ReflectionInput = {
  id?: Maybe<Scalars['ID']>;
  username: Scalars['String'];
  unit: Scalars['Int'];
  summary?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type Submission = {
  __typename?: 'Submission';
  id: Scalars['ID'];
  launch_code: Scalars['ID'];
  unit: Scalars['Int'];
  event_id: Scalars['ID'];
  participants: Array<Maybe<Participant>>;
  reflection?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  evaluated?: Maybe<Scalars['Boolean']>;
  feedback?: Maybe<Scalars['String']>;
};

export type SubmissionInput = {
  id?: Maybe<Scalars['ID']>;
  launch_code: Scalars['ID'];
  unit: Scalars['Int'];
  event_id: Scalars['ID'];
  participants: Array<Maybe<ParticipantInput>>;
  reflection?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  evaluated?: Maybe<Scalars['Boolean']>;
  feedback?: Maybe<Scalars['String']>;
};

export type SubmissionSet = {
  __typename?: 'SubmissionSet';
  event_id: Scalars['ID'];
  unit: Scalars['Int'];
  start_date?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  submissions?: Maybe<Array<Maybe<Submission>>>;
  participant_id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Unit = {
  __typename?: 'Unit';
  num: Scalars['Int'];
  title: Scalars['String'];
  duration: Scalars['String'];
  objectives: Array<Scalars['String']>;
  materials?: Maybe<Array<Maybe<Scalars['String']>>>;
  materials_links?: Maybe<Array<Maybe<Scalars['String']>>>;
  preparation?: Maybe<Array<Maybe<Scalars['String']>>>;
  plan?: Maybe<Array<Maybe<Scalars['String']>>>;
  instructions?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  other_resources?: Maybe<Array<Maybe<Scalars['String']>>>;
  workshop_name?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<Participant>>>;
  start_date?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  event_id?: Maybe<Scalars['ID']>;
};


export type User = {
  __typename?: 'User';
  fusion_id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER',
  Organizer = 'ORGANIZER'
}

export type Workshop = {
  __typename?: 'Workshop';
  workshop_id: Scalars['ID'];
  launch_code: Scalars['ID'];
  username: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  participants: Array<Maybe<Participant>>;
  schedule: Array<Maybe<Event>>;
};

export type WorkshopInput = {
  workshop_id?: Maybe<Scalars['ID']>;
  launch_code?: Maybe<Scalars['ID']>;
  username: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<ParticipantInput>>>;
  schedule?: Maybe<Array<Maybe<EventInput>>>;
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    