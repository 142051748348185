import {Chip, Grid, IconButton, TextField, Typography} from "@material-ui/core";
import {People} from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Add from "@material-ui/icons/Add";
import * as React from "react";
import {useContext, useState} from "react";
import {nanoid} from 'nanoid'
import {WorkshopContext} from "../views/LaunchWorkshop";


interface XProps {
    edit: boolean
}

const Participants = (props: XProps) => {
    const workshopCtx = useContext(WorkshopContext)

    const [editParticipants, setEditParticipants] = useState<boolean>(false)

    let newParticipant:string = ""

    const onCloseChip = (id:string) => {
        if (workshopCtx) {
            workshopCtx.setParticipants(workshopCtx.participants.filter((participant) => participant.participant_id !== id))
        }
    }

    return (
        <React.Fragment>
            <Grid container justify={"flex-start"} alignItems={"center"} spacing={1}>
                <Grid item>
                    <People color="primary" fontSize={"large"}/>
                </Grid>
                <Grid item>
                    <Typography variant={"h6"}>Participants</Typography>
                </Grid>
            </Grid>
            <Grid container justify={"flex-start"} alignItems={"center"} spacing={1}>
                {workshopCtx?.participants.map((p) => {
                    return (
                        <Grid item key={`participant-${p.participant_id}`}>
                            <Chip label={p.name} onDelete={props.edit ? () => {onCloseChip(p.participant_id)} : undefined} color="primary" />
                        </Grid>
                    )
                })}
                {editParticipants && <Grid item>
                    <TextField label="Add attendee's name" onChange={(e) => {
                        newParticipant = e.target.value
                    }}/>
                </Grid>}
                {editParticipants && <Grid item>
                    <IconButton size={"small"} color={"primary"} onClick={() => {
                        if (newParticipant && workshopCtx) {
                            setEditParticipants(false)
                            workshopCtx.setParticipants([...workshopCtx?.participants, {participant_id: nanoid(), name: newParticipant}])
                        }
                    }}>
                        <Check fontSize={"large"}/>
                    </IconButton>
                </Grid>}
                {editParticipants && <Grid item>
                    <IconButton size={"small"} color={"secondary"} onClick={() => { setEditParticipants(false)}}>
                        <Clear fontSize={"large"}/>
                    </IconButton>
                </Grid>}
                {(props.edit && !editParticipants) && <Grid item>
                    <IconButton size={"small"} color={"secondary"} onClick={() => { setEditParticipants(true)}}>
                        <Add fontSize={"large"}/>
                    </IconButton>
                </Grid>}
            </Grid>
        </React.Fragment>
    )
}

export default Participants