import * as React from 'react';
import {useState} from 'react';
import {
    AppBar,
    Chip,
    createStyles,
    Fab,
    Grid,
    IconButton,
    Paper,
    Tab,
    Tabs,
    Theme,
    Typography
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit"
import {makeStyles} from "@material-ui/core/styles"
import {useQuery} from "@apollo/client";
import {Query, QueryReflectionsByUnitArgs, QueryReflectionsByUserArgs, Unit} from "../graphql/generated/graphql-main";
import {mainGqlTemplates} from "../utils/gql-client";
import {Create, Group, Person} from '@material-ui/icons';
import dayjs from "dayjs";
import {UnitSelect} from "../components/UnitSelect";
import {useAuth} from "../components/auth/AuthProvider";
import {EditReflectionDialog} from "../components/dialog/EditReflectionDialog";
import {Redirect} from 'react-router-dom';
import XSnackbar from "../components/XSnackbar";
import {CustomWaiting} from "../components/CustomWaiting";
import {dateTimeFormat, zeroUnit} from "../utils";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        chip: {
            marginBottom: theme.spacing(1),
        }
    }),
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Paper className={"p-10 mt-1"}>
                    {children}
                </Paper>
            )}
        </div>
    );
}

const SelfReflection = () => {
    const classes = useStyles()
    const auth = useAuth()

    const [unitFilter, setUnitFilter] = useState<Unit>(zeroUnit)
    const [openEditReflectionDialog, setOpenEditReflectionDialog] = useState<boolean>(false)
    const [activeId, setActiveId] = useState<string>("")
    const [tab, setTab] = useState<number>(0)
    const [successSnackbar, setSuccessSnackbar] = useState<boolean>(false)
    const [errorSnackbar, setErrorSnackbar] = useState<boolean>(false)
    // This hook allows to distinguish between a reflection being edited or deleted.
    const [deleteSnackbar, setDeleteSnackbar] = useState<boolean>(false)

    const {
        data: myReflections,
        loading: myReflectionsLoading,
        refetch: refetchMyReflections
    } = useQuery<Query, QueryReflectionsByUserArgs>(mainGqlTemplates.GET_MY_REFLECTIONS, {
        variables: {
            username: auth.user?.username || ""
        },
        fetchPolicy: "cache-and-network"
    })
    const {
        data: othersReflections,
        loading: othersReflectionsLoading,
        refetch: refetchOthersReflections
    } = useQuery<Query, QueryReflectionsByUnitArgs>(mainGqlTemplates.GET_OTHERS_REFLECTIONS, {
        variables: {
            unit_num: unitFilter?.num || 0
        },
        fetchPolicy: "cache-and-network"
    })

    const onSaveOrDelete = () => {
        refetchMyReflections().then()
        refetchOthersReflections().then()
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    if (!auth.isAuthenticated)
        return <Redirect to={"/"}/>

    if (myReflectionsLoading || othersReflectionsLoading)
        return <CustomWaiting/>

    return (
        <React.Fragment>
            <div className="pr-5">
                <AppBar position="static" color="default">
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        <Tab label="My reflections" icon={<Person/>}/>
                        <Tab label="All members' reflections" icon={<Group/>}/>
                    </Tabs>
                </AppBar>

                <TabPanel value={tab} index={0}>
                    {myReflectionsLoading && <CustomWaiting/>}
                    {!myReflectionsLoading && <Grid container justify={"flex-start"} spacing={4}>
                        {myReflections?.reflectionsByUser?.map((r) => {
                            return (
                                <Grid container item key={`my-reflections-${r?.id}`}>
                                    <Grid container item justify={"flex-start"} alignItems={"center"} spacing={1}>
                                        <Grid item>
                                            <Chip
                                                label={"Unit " + r?.unit}
                                                color="primary"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                label={r?.date ? dayjs(r?.date).format(dateTimeFormat) : ""}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton color={"secondary"} onClick={() => {
                                                if (r?.id) {
                                                    setActiveId(r?.id)
                                                }
                                                setOpenEditReflectionDialog(true)
                                            }}>
                                                <EditIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Typography align={"left"} variant={"h6"}>{r?.summary}</Typography>
                                    </Grid>
                                    <Grid container item>
                                        <Typography align={"left"} variant={"body1"} gutterBottom>
                                            {r?.content}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>}
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    {othersReflectionsLoading && <CustomWaiting/>}
                    {!othersReflectionsLoading && <Grid container justify={"flex-start"} spacing={4}>
                        <Grid container item>
                            <UnitSelect
                                selectedUnit={unitFilter}
                                setSelectedUnit={(arg) => {
                                    setUnitFilter(arg)
                                }
                                }
                            />
                        </Grid>
                        {othersReflections?.reflectionsByUnit?.map((r, i) => {
                            return (
                                <Grid container item key={`others-reflections-${r?.id}-${i}`}>
                                    <Grid container item justify={"flex-start"} alignItems={"center"} spacing={1}>
                                        <Grid item>
                                            <Chip
                                                className={classes.chip}
                                                label={r?.username}
                                                color="primary"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                className={classes.chip}
                                                label={r?.date ? dayjs(r?.date).format(dateTimeFormat) : ""}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Typography align={"left"} variant={"h6"}>{r?.summary}</Typography>
                                    </Grid>
                                    <Grid container item>
                                        <Typography align={"left"} variant={"body1"} gutterBottom>
                                            {r?.content}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>}
                </TabPanel>
            </div>

            {openEditReflectionDialog && (
                <EditReflectionDialog
                    open={openEditReflectionDialog}
                    setOpen={(arg) => setOpenEditReflectionDialog(arg)}
                    onSaveOrDelete={onSaveOrDelete}
                    id={activeId}
                    setSuccessSnackbar={setSuccessSnackbar}
                    setErrorSnackbar={setErrorSnackbar}
                    setDeleteSnackbar={setDeleteSnackbar}
                />
            )}

            <Fab
                variant="extended"
                color="secondary"
                className={classes.fab}
                onClick={() => {
                    setActiveId("")
                    setOpenEditReflectionDialog(true)
                }}
            >
                <Create className={classes.extendedIcon}/> Share my reflections
            </Fab>

            <XSnackbar
                snackbar={successSnackbar}
                setSnackbar={setSuccessSnackbar}
                message={`Your reflections have been successfully ${deleteSnackbar ? "deleted" : "published"}!`}
                severity={"success"}
            />
            <XSnackbar
                snackbar={errorSnackbar}
                setSnackbar={setErrorSnackbar}
                message={`Failed to ${deleteSnackbar ? "delete" : "publish"} your reflections!`}
                severity={"error"}
            />
        </React.Fragment>
    )
}

export default SelfReflection