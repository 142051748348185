import * as React from 'react';
import {
    Chip,
    createStyles,
    Grid,
    IconButton,
    Switch,
    Theme,
    Typography
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import {IWorkshop} from "../types";
import EditableWorkshopInfo from "../components/EditableWorkshopInfo";
import WorkshopInfo from "../components/WorkshopInfo";
import {useEffect, useState} from "react";
import Participants from "../components/Participants";
import {Redirect, useHistory} from 'react-router-dom';
import {
    Event,
    Participant,
    Query,
    QueryWorkshopArgs,
    Mutation,
    MutationUpdateWorkshopArgs,
    SubmissionSet,
    Submission,
    QuerySubmissionsArgs,
    ParticipantInput,
    EventInput
} from "../graphql/generated/graphql-main";
import {useMutation, useQuery} from "@apollo/client";
import {mainGqlTemplates} from "../utils/gql-client";
import {useParams} from "react-router";
import {WorkshopContext} from "./LaunchWorkshop";
import dayjs from "dayjs";
import {useAuth} from "../components/auth/AuthProvider";
import XSnackbar from "../components/XSnackbar";
import {CustomWaiting} from "../components/CustomWaiting";
import {isUnitEvaluated, stripTypename} from "../utils";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        evaluated: {
            backgroundColor: "#5fbd4b !important;",
            fontSize: 14,
            "&:hover": {
                backgroundColor: '#428434'
            },
            "&:click": {
                backgroundColor: '#7fca6f'
            }
        },
        toBeEvaluated: {
            backgroundColor: "#F9C813 !important;",
            fontSize: 14,
            "&:hover": {
                backgroundColor: '#ae8c0d'
            },
            "&:click": {
                backgroundColor: '#fad342'
            }
        },
        evaluatedLegend: {
            backgroundColor: "#5fbd4b",
            fontSize: 14,
            margin: theme.spacing(1),
        },
        toBeEvaluatedLegend: {
            backgroundColor: "#F9C813",
            fontSize: 14,
            margin: theme.spacing(1),
        }
    }),
)

const DetailedView = () => {
    const classes = useStyles()
    const auth = useAuth()
    const {workshop} = useParams<{ workshop: string }>();

    const history = useHistory()

    const [edit, setEdit] = useState<boolean>(false)
    const [switchState, setSwitchState] = useState<{[key: string]: boolean}>({})
    // Ensures that change is user-driven (not because of page loading)
    const [modifiedSwitchedState, setModifiedSwitchedState] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<string>("")
    const [endDate, setEndDate] = useState<string>("")
    const [workshopName, setWorkshopName] = useState<string>("")
    const [username, setUsername] = useState<string>("")
    const [workshopId, setWorkshopId] = useState<string>("")
    const [launchCode, setLaunchCode] = useState<string>("")
    const [participants, setParticipants] = useState<Participant[]>([])
    const [events, setEvents] = useState<Event[]>([])
    const [successSnackbar, setSuccessSnackbar] = useState<boolean>(false)
    const [errorSnackbar, setErrorSnackbar] = useState<boolean>(false)

    const {data:workshopData, loading: workshopLoading} = useQuery<Query, QueryWorkshopArgs>(mainGqlTemplates.GET_WORKSHOP, {
        variables: {launch_code: workshop},
        fetchPolicy: "cache-and-network"
    })

    const {data:submissionData, loading: submissionLoading} = useQuery<Query, QuerySubmissionsArgs>(mainGqlTemplates.GET_SUBMISSIONS, {
        variables: {launch_code: workshop},
        fetchPolicy: "cache-and-network"
    })

    const [updateWorkshop] = useMutation<Mutation, MutationUpdateWorkshopArgs>(mainGqlTemplates.UPDATE_WORKSHOP)

    useEffect(() => {
        if (workshopData?.workshop) {
            const {name, workshop_id, username, start_date, end_date, launch_code, participants, schedule}
                = workshopData?.workshop
            setWorkshopName(name || "")
            setUsername(username || "")
            setWorkshopId(workshop_id || "")
            setStartDate(start_date || "")
            setEndDate(end_date || "")
            setLaunchCode(launch_code || "")
            setParticipants(participants as Participant[] || [])
            setEvents(schedule as Event[] || [])
        }
    }, [workshopData])

    useEffect(() => {
        if (submissionData?.submissions) {
            let state = {};
            (submissionData?.submissions as SubmissionSet[]).forEach((unit) => {
                Object.assign(state, {[unit.event_id]: unit?.closed as boolean})
            })
            setSwitchState(state)
        }
    }, [submissionData])

    useEffect(() => {
        if (modifiedSwitchedState) {
            update().then()
        }
    }, [switchState])

    const update = async () => {
        const participantArr: ParticipantInput[] = []
        participants.forEach((p) => {
            participantArr.push(stripTypename(p))
        })
        const eventArr:EventInput[] = [];
        events.forEach((e:Event) => {
            eventArr.push({
                event_id: e.event_id,
                unit: e.unit,
                start_date: e.start_date,
                end_date: e.end_date,
                closed: switchState[e.event_id]
            })
        })
        const res = await updateWorkshop({
            variables: {
                workshopInput: {
                    username: username,
                    workshop_id: workshopId,
                    launch_code: launchCode,
                    start_date: startDate,
                    end_date: endDate,
                    name: workshopName,
                    participants: participantArr,
                    schedule: eventArr
                }
            }
        })
        if (res?.data?.updateWorkshop) {
            setSuccessSnackbar(true)
        } else {
            setErrorSnackbar(true)
        }
    }

    const xWorkshop: IWorkshop = {
        username: username,
        workshopId: workshopId,
        startDate: startDate,
        endDate: endDate,
        workshopName: workshopName,
        launchCode: launchCode,
        participants: participants,
        events: events,
        setUsername: setUsername,
        setWorkshopId: setWorkshopId,
        setStartDate: setStartDate,
        setEndDate: setEndDate,
        setWorkshopName: setWorkshopName,
        setParticipants: setParticipants,
        setEvents: setEvents
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Looks like e.target.checked returns previous state and the new state (as supposed in Material-UI documentation)
        setSwitchState({ ...switchState, [e.target.name]: !e.target.checked });
        setModifiedSwitchedState(true)
    };

    if (!auth.isAuthenticated)
        return <Redirect to={"/"}/>

    if (workshopLoading || submissionLoading)
        return <CustomWaiting/>

    return (
        <WorkshopContext.Provider value={xWorkshop}>
            <Typography align={"left"} variant={"h4"} component={"h4"} gutterBottom>Detailed View&nbsp;
                {edit && <IconButton onClick={() => {
                    update().then()
                    setEdit(false)
                }}>
                    <SaveIcon fontSize="default"/>
                </IconButton>}
                {!edit && <IconButton onClick={() => {setEdit(true)}}>
                    <EditIcon fontSize="default"/>
                </IconButton>}
            </Typography>
            {edit ? <EditableWorkshopInfo/> : <WorkshopInfo/>}
            <br/>
            <Participants edit={edit}/>
            <br/>
            <br/>
            <Typography variant={"h4"} component={"h4"} align={"left"} gutterBottom>
                Click a group to evaluate the activity
            </Typography>
            <Typography className={"text-sm"} variant={"body1"} align={"left"}>
                Activate a unit by turning on the switch. This allows participants to submit their work for the corresponding unit.<br/>
                Deactivate a unit by turning off the switch. This prevents new submissions and allows all teams to see each others' work for this unit.<br/>
                When new groups will submit their work, new G#'s will be added at the right of the unit number.
            </Typography>
            <br/>
            {submissionData?.submissions?.map((unitSet) => {
                return (
                    <Grid container item key={`submission-${unitSet?.event_id}`} justify={"flex-start"} alignItems={"center"} spacing={2}>
                        <Grid item>
                            <Switch
                                checked={!switchState[unitSet?.event_id as string]}
                                onChange={handleChange}
                                color="primary"
                                name={unitSet?.event_id}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"}>
                                {dayjs(unitSet?.start_date as string).format("MMM DD, YYYY")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Chip
                                className={isUnitEvaluated(unitSet?.submissions as Submission[]) ? classes.evaluated : classes.toBeEvaluated}
                                label={`Unit ${unitSet?.unit}`}
                                clickable
                                onClick={() => {
                                    history.push(`/unit/${unitSet?.unit}`)
                                }}
                            />
                        </Grid>
                        {unitSet?.submissions?.map((submission, i) => {
                            return (
                                <Grid item key={`submission-G${i}-${submission?.event_id}`}>
                                    <Chip
                                        className={submission?.evaluated ? classes.evaluated : classes.toBeEvaluated}
                                        label={`G${i}`}
                                        clickable
                                        onClick={() => {
                                            history.push(`/evaluation-view/${i}/${submission?.id}`, {from: "detailed-view"})
                                        }}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            })}
            <br/>
            <Grid container justify={"flex-start"}>
                <Chip
                    className={classes.toBeEvaluatedLegend}
                    label={"To be evaluated"}
                />
                <Chip
                    className={classes.evaluatedLegend}
                    label={"Evaluated"}
                />
            </Grid>
            <XSnackbar snackbar={successSnackbar}
                       setSnackbar={setSuccessSnackbar}
                       message={"The workshop has been successfully updated!"}
                       severity={"success"}
            />
            <XSnackbar snackbar={errorSnackbar}
                       setSnackbar={setErrorSnackbar}
                       message={"The workshop cannot be updated!"}
                       severity={"error"}
            />
        </WorkshopContext.Provider>
    )
}

export default DetailedView