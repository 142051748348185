import React from "react"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import {useHistory} from "react-router-dom"

export const Error404 = () => {
    const history = useHistory()

    return (
        <Container className="mt-20 text-center">
            <Card className="auth-card bg-transparent shadow-none rounded-0 mb-0 w-100 text-center">
                <h1 className="font-large-2 my-1">404 - Page Not Found!</h1>
                <Button variant="contained" color="primary" onClick={() => history.push("/")}>
                    Home Page
                </Button>
            </Card>
        </Container>
    )
}

export default Error404
