import * as React from 'react';
import {Chip, createStyles, Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router";
import {mainGqlTemplates} from "../utils/gql-client";
import {useQuery} from "@apollo/client";
import {Query, QuerySubmissionsByParticipantArgs} from "../graphql/generated/graphql-main";
import {Redirect, useHistory} from "react-router-dom";
import {useAuth} from "../components/auth/AuthProvider";
import {CustomWaiting} from "../components/CustomWaiting";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(1),
            fontSize: 14
        },
        evaluated: {
            backgroundColor: "#5fbd4b !important;",
            fontSize: 14,
            "&:hover": {
                backgroundColor: '#428434'
            },
            "&:click": {
                backgroundColor: '#428434'
            }
        },
        toBeEvaluated: {
            backgroundColor: "#F9C813 !important;",
            fontSize: 14,
            "&:hover": {
                backgroundColor: '#ae8c0d'
            },
            "&:click": {
                backgroundColor: '#fad342'
            },
        }
    }),
);

const ParticipantList = () => {
    const classes = useStyles()
    const auth = useAuth()
    const history= useHistory()

    const {workshop:workshopParam} = useParams<{workshop:string, participant:string}>()

    const {data, loading} = useQuery<Query, QuerySubmissionsByParticipantArgs>(mainGqlTemplates.SUBMISSIONS_BY_PARTICIPANT, {
        variables: {
            launch_code: workshopParam
        }
    })

    if (!auth.isAuthenticated)
        return <Redirect to={"/"}/>

    if (loading)
        return <CustomWaiting/>

    return (
        <React.Fragment>
            {data?.submissionsByParticipant?.map((submissionSet, idx) => {
                return (
                    <React.Fragment key={`participant-${idx}`}>
                        <Typography key={`participant-list-${submissionSet?.participant_id}`} align={"left"} variant={"h4"} gutterBottom>
                            {submissionSet?.name}
                        </Typography>
                        <Grid container justify={"flex-start"} spacing={2}>
                            {submissionSet?.submissions?.map((s) => {
                                return (
                                    <Grid item>
                                        <Chip
                                            key={`participant-list-${submissionSet?.participant_id}-${s?.id}`}
                                            className={s?.evaluated ? classes.evaluated : classes.toBeEvaluated}
                                            label={`U${s?.unit}`}
                                            clickable
                                            onClick={() => {
                                                history.push(`/evaluation-view/${s?.unit}/${s?.id}`, {from: "participant-list"})
                                            }}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <br/>
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
}

export default ParticipantList