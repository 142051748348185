import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import LaunchImg from "../assets/img/launch.png"
import LaunchSvg from "../assets/icons/launch.svg"
import {mainGqlTemplates} from "../utils/gql-client";
import {useLazyQuery} from "@apollo/client";
import {Query, QueryWorkshopUnitArgs,} from "../graphql/generated/graphql-main";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {menuActions} from "../store/menu";
import XSnackbar from "../components/XSnackbar";


export const LaunchIcon = () => {
    return <img src={LaunchSvg} height={32} width={32} alt={"LAUNCH logo"}/>
}

const LaunchHome = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const [launchCode, setLaunchCode] = useState<string>("")
    const [errorSnackbar, setErrorSnackbar] = useState<boolean>(false)

    // https://www.apollographql.com/blog/apollo-client-react-how-to-query-on-click-c1d4fecf9b66/
    const [validateLaunchcode, {data}] = useLazyQuery<Query, QueryWorkshopUnitArgs>(mainGqlTemplates.WORKSHOP_UNIT)

    // The query 'workshopUnit' returns a null unit if the launchcode is not valid. If valid, the first unit of the
    // workshop is returned and used to load the next page 'My Workshop'.
    useEffect(() => {
        if (data) {
            if (data?.workshopUnit) {
                let {closed, num, event_id} = data?.workshopUnit
                history.push(`/${closed ? "workshop-submissions" : "my-workshop"}/${launchCode}/${num}/${event_id}`)
                dispatch(menuActions.connectAsParticipant())
            } else {
                setErrorSnackbar(true)
            }
        }
    }, [data])

    // todo spacing={4} causes weird horizontal scrollbar due to (calc ... + 32px)
    return (
        <React.Fragment>
            <Grid container justify={"center"} spacing={4}>
                <Grid container item justify={"center"}>
                    <Typography className={"pt-20 text-6xl"}>LAUNCH</Typography>
                </Grid>
                <Grid container item justify={"center"}>
                    <Typography className={"text-2xl"}>Your roadmap for junior coding workshops</Typography>
                </Grid>
                <Grid container item justify={"center"}>
                    {/*https://reactgo.com/react-images/*/}
                    <img src={LaunchImg} alt="Launch logo" width="256" height="256"/>
                </Grid>
                <Grid container item justify={"center"}>
                    <Typography className={"text-xl"}>Enter your workshop code below</Typography>
                </Grid>
                <Grid container item justify={"center"}>
                    <TextField className={"w-1/4"} id="launch-code" label="Launchcode" variant="outlined"
                               value={launchCode}
                               onChange={(e) => {
                                   setLaunchCode(e.target.value)
                               }}
                    />
                </Grid>
                <Grid container item justify={"center"}>
                    <Button size="large"
                            endIcon={<LaunchIcon/>}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                validateLaunchcode({
                                    variables: {
                                        launch_code: launchCode
                                    }
                                })
                            }}
                    >
                        <Typography variant={"h6"}>LIFT OFF</Typography>
                    </Button>
                </Grid>
            </Grid>

            <XSnackbar
                snackbar={errorSnackbar}
                setSnackbar={setErrorSnackbar}
                message={"No workshop exists for this launchcode!"}
                severity={"error"}
            />
        </React.Fragment>
    )
}

export default LaunchHome