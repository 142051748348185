import React, {createContext, useState} from "react";
import {createStyles, Fab, Grid, Theme, Typography} from "@material-ui/core"
import XCalendar from "../components/XCalendar"
import {LaunchIcon} from "./LaunchHome"
import EditableWorkshopInfo from "../components/EditableWorkshopInfo"
import Participants from "../components/Participants"
import {useMutation} from '@apollo/client';
import {Event, EventInput, Participant, ParticipantInput} from "../graphql/generated/graphql-main";
import {Redirect, useHistory} from "react-router-dom";
import {mainGqlTemplates} from "../utils/gql-client";
import {IWorkshop} from "../types";
import {makeStyles} from "@material-ui/core/styles";
import {useAuth} from "../components/auth/AuthProvider";
import XSnackbar from "../components/XSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {menuActions} from "../store/menu";
import {RootState} from "../store/root-reducer";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    }),
);

export const WorkshopContext = createContext<IWorkshop | undefined>(undefined)

const LaunchWorkshop = () => {
    const classes = useStyles()
    const auth = useAuth()
    const history = useHistory()
    const dispatch = useDispatch()

    const openMenuItems = useSelector((state: RootState) => state.menu.openMenuItems)

    const [startDate, setStartDate] = useState<string>("")
    const [endDate, setEndDate] = useState<string>("")
    const [workshopName, setWorkshopName] = useState<string>("")
    const [participants, setParticipants] = useState<Participant[]>([])
    const [events, setEvents] = useState<Event[]>([])
    const [successSnackbar, setSuccessSnackbar] = useState<boolean>(false)
    const [errorSnackbar, setErrorSnackbar] = useState<boolean>(false)

    const [updateWorkshop] = useMutation(mainGqlTemplates.UPDATE_WORKSHOP)

    const xWorkshop: IWorkshop = {
        startDate: startDate,
        endDate: endDate,
        workshopName: workshopName,
        participants: participants,
        events: events,
        setStartDate: setStartDate,
        setEndDate: setEndDate,
        setWorkshopName: setWorkshopName,
        setParticipants: setParticipants,
        setEvents: setEvents
    }

    const collapseMenu = (menuItem: string) => {
        let copyOpenMenuItems = [...openMenuItems]
        copyOpenMenuItems.push(menuItem)
        dispatch(menuActions.setOpenMenuItems(copyOpenMenuItems))
    }

    if (!auth.isAuthenticated)
        return <Redirect to={"/"}/>

    return (
        <WorkshopContext.Provider value={xWorkshop}>
            <Typography align={"left"} variant="h4" component="h4">
                Launch a workshop
            </Typography>
            <EditableWorkshopInfo/>

            <br/>
            <Participants edit={true}/>
            <br/>
            <br/>

            {(startDate && endDate) && (
                <div>
                    <Typography align={"left"} variant="h4" component={"h4"} gutterBottom>
                        Click a date and select the unit to cover
                    </Typography>

                    <XCalendar/>
                    <br/>

                    <Grid container justify={"flex-end"}>
                        <Fab
                            variant="extended"
                            color={"secondary"}
                            className={classes.fab}
                            onClick={async () => {
                                const res = await updateWorkshop({
                                    variables: {
                                        workshopInput: {
                                            username: auth.user?.username,
                                            name: workshopName,
                                            start_date: startDate,
                                            end_date: endDate,
                                            participants: participants as ParticipantInput[],
                                            schedule: events as EventInput[]
                                        }
                                    }
                                })
                                // The returned id is the workshop launchcode and not the workshop id.
                                let launchCode = res?.data?.updateWorkshop
                                if (launchCode) {
                                    setSuccessSnackbar(true)
                                    history.push(`/detailed-view/${launchCode}`)
                                    dispatch(menuActions.setActiveMenuItem(`detailed-view-${launchCode}`))
                                    collapseMenu(`my-workshop-${launchCode}`)
                                } else {
                                    setErrorSnackbar(true)
                                }
                            }}
                        >
                            Launch my workshop&nbsp;
                            <LaunchIcon/>
                        </Fab>
                    </Grid>
                </div>
            )}

            <XSnackbar snackbar={successSnackbar}
                       setSnackbar={setSuccessSnackbar}
                       message={"Workshop successfully created"}
                       severity={"success"}
            />
            <XSnackbar snackbar={errorSnackbar}
                       setSnackbar={setErrorSnackbar}
                       message={"Failed to create workshop"}
                       severity={"error"}
            />
        </WorkshopContext.Provider>
    )
}

export default LaunchWorkshop