import React, {useState} from "react"
import {
    List, ListItem, ListItemIcon, ListItemText,
    Typography,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete";
import {LaunchIcon} from "../../views/LaunchHome";
import {mainGqlTemplates} from "../../utils/gql-client";
import {useQuery} from "@apollo/client";
import {Query, QueryUnitArgs} from "../../graphql/generated/graphql-main";
import {Event} from "../../graphql/generated/graphql-main";
import {ChevronRight} from "@material-ui/icons";
import ClosableDialog from "./ClosableDialog";
import DeleteDialog from "./DeleteDialog";
import {ButtonAction} from "../../types";
import {CustomWaiting} from "../CustomWaiting";


interface InfoDialogProps {
    onDelete: (arg:string) => void
    open: boolean
    setOpen: (arg: boolean) => void
    clickedEvent: Event | undefined
}

const InfoDialog = (props: InfoDialogProps) => {
    const [deleteEventDialog, setDeleteEventDialog] = useState<boolean>(false)

    const {data, loading} = useQuery<Query, QueryUnitArgs>(mainGqlTemplates.GET_UNIT, {
        variables: {
            num: props.clickedEvent?.unit as number
        }
    })

    const actions:ButtonAction[] = [
        {name: "Remove", icon: <DeleteIcon/>, color: "secondary", variant: "contained", action: () => {
            setDeleteEventDialog(true)
        }}
    ]

    return (
        <ClosableDialog open={props.open} setOpen={props.setOpen} icon={<LaunchIcon/>} title={"LAUNCH"}
                        actions={loading ? [] : actions}
        >
            <React.Fragment>
                {loading && <CustomWaiting/>}
                {!loading && <React.Fragment>
                    <Typography variant={"h6"} align={"left"} component={"h2"} color={"primary"}>
                        <b>Unit {data?.unit?.num} -</b> {data?.unit?.title}
                    </Typography>
                    <Typography align={"left"} color="textSecondary" gutterBottom>
                        Duration: {data?.unit?.duration}
                    </Typography>
                    <br/>
                    <Typography align={"left"} color="textSecondary">
                        <b>Learning Objectives</b>
                    </Typography>
                    <List dense>
                        {data?.unit?.objectives.map(item => {
                            return (
                                <ListItem>
                                    <ListItemIcon><ChevronRight/></ListItemIcon>
                                    <ListItemText primary={item}/>
                                </ListItem>
                            )
                        })}
                    </List>
                    <Typography align={"left"} color="textSecondary">
                        <b>Materials</b>
                    </Typography>
                    <List dense>
                        {data?.unit?.materials?.map(item => {
                            return (
                                <ListItem>
                                    <ListItemIcon><ChevronRight/></ListItemIcon>
                                    <ListItemText primary={item}/>
                                </ListItem>
                            )
                        })}
                    </List>
                    <Typography align={"left"} color="textSecondary">
                        <b>Preparation</b>
                    </Typography>
                    <List dense>
                        {data?.unit?.preparation?.map(item => {
                            return (
                                <ListItem>
                                    <ListItemIcon><ChevronRight/></ListItemIcon>
                                    <ListItemText primary={item}/>
                                </ListItem>
                            )
                        })}
                    </List>
                    {deleteEventDialog && <DeleteDialog open={deleteEventDialog} setOpen={setDeleteEventDialog}
                                                        onDelete={() => {
                                                            props.onDelete(props.clickedEvent?.event_id as string)
                                                        }}
                                                        mainMessage={"Are you sure you want to delete this unit from your workshop?"}
                                                        subMessage={"If students have submitted their work for this unit " +
                                                        "and this unit is deleted, you will no longer be able to view and " +
                                                        "provide feedback over their submissions."}
                    />}
                </React.Fragment>}
            </React.Fragment>
        </ClosableDialog>
    )
}

export default InfoDialog