import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import Calendar from "@toast-ui/react-calendar"
import {ICalendarInfo, ISchedule} from "tui-calendar"
import {createStyles, Grid, IconButton, Theme, Typography} from "@material-ui/core";
import 'tui-calendar/dist/tui-calendar.css'
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-time-picker/dist/tui-time-picker.css'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {makeStyles} from "@material-ui/core/styles"
import AddEventDialog from "./dialog/AddEventDialog";
import {Event, Unit} from "../graphql/generated/graphql-main";
import {nanoid} from "nanoid";
import {differenceInCalendarMonths} from "date-fns";
import InfoDialog from "./dialog/InfoDialog";
import InvalidEventDialog from "./dialog/InvalidEventDialog";
import {WorkshopContext} from "../views/LaunchWorkshop";

import dayjs from "dayjs";
import IsSameOrBefore from "dayjs/plugin/isSameOrBefore"
import IsSameOrAfter from "dayjs/plugin/isSameOrAfter"
import IsBetween from "dayjs/plugin/isBetween"
import Utc from "dayjs/plugin/utc"

dayjs.extend(IsSameOrBefore)
dayjs.extend(IsSameOrAfter)
dayjs.extend(IsBetween)
dayjs.extend(Utc)

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
        },
        monthLabel: {
            width: 200
        }
    }),
);

const calendars: ICalendarInfo[] = [
    {
        id: "1",
        name: "Workshop",
        color: "#ffffff",
        bgColor: "#3f51b5",
        dragBgColor: "#002984",
        borderColor: "#757de8"
    }
];

/**
 *
 * @constructor
 */
const XCalendar = () => {
    const classes = useStyles()
    const calendarRef: any = useRef()
    const workshopCtx = useContext(WorkshopContext)

    console.info(`workshop context`, workshopCtx)

    const [currentDates, setCurrentDates] = useState<string>("")
    const [addEventDialog, setAddEventDialog] = useState<boolean>(false)
    const [invalidEventDialog, setInvalidEventDialog] = useState<boolean>(false)
    const [infoDialog, setInfoDialog] = useState<boolean>(false)
    const [scheduleData, setScheduleData] = useState<ISchedule>({})
    const [clickedEvent, setClickedEvent] = useState<Event>()
    // This hook allow to update the workshop events in the database on creation, update, and deletion of events only;
    // this is to prevent to update the database on page loading when no change even occurs.
    const [updateNum, setUpdateNum] = useState<number>(0)

    useEffect(() => {
        const calendarInstance = calendarRef.current.getInstance()
        if (workshopCtx?.startDate) {
            setCurrentDates(workshopCtx?.startDate)
            calendarInstance.setDate(new Date(workshopCtx?.startDate))
        }
    }, [workshopCtx]);

    useEffect(() => {
        if (workshopCtx?.onUpdate && updateNum !== 0)
            workshopCtx.onUpdate()
    }, [updateNum, workshopCtx])

    const monthDiff = (oldest: Date, newest: Date) => {
        let date1 = new Date(oldest.getFullYear(), oldest.getMonth(), 1)
        let date2 = new Date(newest.getFullYear(), newest.getMonth(), 1)
        return dayjs(date1).diff(dayjs(date2), 'month')
    }

    const nextMonth = () => {
        const calendarInstance = calendarRef.current.getInstance()
        if (monthDiff(new Date(workshopCtx?.endDate as string), calendarInstance.getDate().toDate()) > 0) {
            calendarInstance.next()
            setCurrentDates(calendarInstance.getDate().toDate().toISOString())
        }
    }

    const previousMonth = () => {
        const calendarInstance = calendarRef.current.getInstance()
        if (monthDiff(calendarInstance.getDate().toDate(), new Date(workshopCtx?.startDate as string)) > 0) {
            calendarInstance.prev()
            setCurrentDates(calendarInstance.getDate().toDate().toISOString())
        }
    }

    const onClickSchedule = useCallback((scheduleData) => {
        setClickedEvent({
            event_id: scheduleData.schedule.id,
            unit: Number(scheduleData.schedule.body),
            start_date: scheduleData.schedule.start._date.toISOString(),
            end_date: scheduleData.schedule.end._date.toISOString()
        })
        setInfoDialog(true)
    }, [])

    /**
     *
     */
    const onBeforeCreateSchedule = (scheduleData: ISchedule) => {
        console.info(`schedule-data`, scheduleData)
        console.info(`workshop context (onBeforeCreateSchedule)`, workshopCtx)

        const dateProposed = dayjs(new Date((scheduleData.start! as Date)))
        const startWorkshop = dayjs(workshopCtx?.startDate || Date.now())
        const endWorkshop = dayjs(workshopCtx?.endDate || Date.now())

        setScheduleData(scheduleData)

        // determine whether proposed event date
        // const valid = dateProposed >= startWorkshop && dateProposed <= endWorkshop

        // https://day.js.org/docs/en/plugin/is-between#docsNav
        const valid2 = dateProposed.isBetween(startWorkshop, endWorkshop, 'd', '[]')

        console.info(`valid2`, valid2)

        if (!valid2) {
            setInvalidEventDialog(true)
            return
        }

        // normal case = proposed date for event is within range of workshop dates
        setAddEventDialog(true)
    }

    const onBeforeUpdateSchedule = useCallback((e) => {
        const {schedule, changes} = e
        let updatedEvent = {...workshopCtx?.events?.filter(event => event.event_id === schedule.id)[0]} as Event
        let otherEvents = workshopCtx?.events?.filter(event => event.event_id !== schedule.id)
        if (updatedEvent && otherEvents) {
            if (changes?.start) {
                updatedEvent.start_date = changes.start._date.toISOString()
            }
            if (changes?.end) {
                updatedEvent.end_date = changes.end._date.toISOString()
            }
            if (workshopCtx?.setEvents) {
                workshopCtx.setEvents(otherEvents.length > 0 ? [...otherEvents, updatedEvent] : [updatedEvent])
            }
            setUpdateNum(updateNum + 1)
        }
    }, [workshopCtx]);

    const toISchedule = (): ISchedule[] => {
        return workshopCtx?.events?.map((e) => {
            return {
                calendarId: "1",
                category: "allday",
                isVisible: true,
                title: `Unit ${e.unit}`,
                id: e.event_id,
                body: String(e.unit),
                start: e.start_date,
                end: e.end_date
            } as ISchedule
        }) || []
    }

    const onAddEvent = (unit: Unit | null, scheduleData: any) => {
        const eventId = nanoid()
        const event: Event = {
            event_id: eventId,
            unit: unit?.num as number,
            start_date: scheduleData.start._date.toISOString(),
            end_date: scheduleData.end._date.toISOString(),
            closed: false
        }
        if (workshopCtx?.setEvents) {
            workshopCtx.setEvents([...workshopCtx?.events as Event[], event])
        }
        setUpdateNum(updateNum + 1)
    }

    const onDelete = (eventId: string) => {
        if (workshopCtx) {
            setInfoDialog(false)
            const newArray = workshopCtx?.events?.filter(event => event.event_id !== eventId)
            if (workshopCtx?.setEvents) {
                workshopCtx.setEvents(newArray as Event[])
            }
            setUpdateNum(updateNum + 1)
        }
    }

    return (
        <React.Fragment>
            <Grid container justify={"center"} alignItems={"center"} spacing={1}>
                <Grid item>
                    <IconButton
                        onClick={previousMonth}
                        disabled={!workshopCtx?.startDate || !(differenceInCalendarMonths(
                            new Date(currentDates), new Date(workshopCtx?.startDate)) >= 1)}
                    >
                        <ChevronLeftIcon fontSize="default"/>
                    </IconButton>

                </Grid>
                <Grid item>
                    <Typography className={classes.monthLabel} variant={"h5"}>
                        {dayjs(currentDates).format("MMMM YYYY")}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={nextMonth}
                        disabled={!workshopCtx?.endDate || !(differenceInCalendarMonths(
                            new Date(workshopCtx?.endDate), new Date(currentDates)) >= 1)}
                    >
                        <ChevronRightIcon fontSize="default"/>
                    </IconButton>
                </Grid>
            </Grid>

            <div className="pr-3 pb-3">
                <Calendar
                    ref={calendarRef}
                    usageStatistics={false}
                    height="900px"
                    view="month"
                    calendars={calendars}
                    schedules={toISchedule() as ISchedule[]}
                    onClickSchedule={onClickSchedule}
                    onBeforeCreateSchedule={onBeforeCreateSchedule}
                    onBeforeUpdateSchedule={onBeforeUpdateSchedule}
                />
            </div>

            {addEventDialog && (
                <AddEventDialog
                    onAdd={onAddEvent}
                    open={addEventDialog}
                    setOpen={setAddEventDialog}
                    scheduleData={scheduleData}
                />
            )}

            {invalidEventDialog && (
                <InvalidEventDialog
                    open={invalidEventDialog}
                    setOpen={setInvalidEventDialog}
                    scheduleData={scheduleData}
                />
            )}

            {infoDialog && (
                <InfoDialog
                    onDelete={onDelete}
                    open={infoDialog}
                    setOpen={setInfoDialog}
                    clickedEvent={clickedEvent}
                />
            )}
        </React.Fragment>
    )
}

export default XCalendar