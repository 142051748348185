import * as React from 'react';
import {
    Button, Card, CardContent, CardMedia,
    Chip,
    createStyles,
    Grid,
    TextField,
    Theme,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete"
import {useParams} from "react-router";
import {mainGqlTemplates} from "../utils/gql-client";
import {useQuery} from "@apollo/client";
import {
    Query, QuerySubmissionArgs,
    QueryWorkshopUnitArgs
} from "../graphql/generated/graphql-main";
import dayjs from "dayjs";
import {ArrowForward, People} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {CustomWaiting} from "../components/CustomWaiting";
import {dateTimeFormat} from "../utils";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 400,
            height: 40
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            marginRight: theme.spacing(1),
        },
        margin_1: {
            margin: theme.spacing(1),
        },
        video: {
            width: "100%",
            height: 360
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        }
    }),
);

const SubmittedWorkshop = () => {
    const classes = useStyles()
    const history = useHistory()
    const {submission:submissionParam} = useParams<{submission: string}>()

    const {data:submissionData, loading:submissionLoading} = useQuery<Query, QuerySubmissionArgs>(mainGqlTemplates.GET_SUBMISSION, {
        variables: {
            submission_id: submissionParam,
        }
    })

    const {data:workshopData, loading:workshopLoading} = useQuery<Query, QueryWorkshopUnitArgs>(mainGqlTemplates.WORKSHOP_UNIT, {
        variables: {
            launch_code: submissionData?.submission?.launch_code || "",
            unit_num: Number(submissionData?.submission?.unit),
            event_id: submissionData?.submission?.event_id || ""
        }
    })

    if (submissionData?.submission?.id==="-1") {
        history.push("/")
    }

    if (submissionLoading || workshopLoading)
        return <CustomWaiting/>

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid container item>
                    <Typography align={"left"} variant="h4" component={"h1"}>
                        {workshopData?.workshopUnit?.workshop_name}
                    </Typography>
                </Grid>
                <Grid container item alignItems={"center"}>
                    <Chip className={classes.chip} color={"secondary"} label={`Unit ${submissionData?.submission?.unit}`}/>
                    <Chip className={classes.chip} color={"secondary"} label={`Submitted on: ${dayjs(submissionData?.submission?.date as string).format(dateTimeFormat)}`}/>
                    {/*<Typography align={"left"} variant="h5">*/}
                    {/*    Unit {submissionData?.submission?.unit}&nbsp;*/}
                    {/*</Typography>*/}
                    {/*<Typography align={"left"} variant="body1" color={"secondary"}>*/}
                    {/*    (submitted on {dayjs(submissionData?.submission?.date as string).format(dateTimeFormat)})*/}
                    {/*</Typography>*/}
                </Grid>
                <Grid container item/>
                <Grid container justify={"flex-start"} alignItems={"center"} spacing={1}>
                    <Grid item>
                        <People color="primary" fontSize={"large"}/>
                    </Grid>
                    <Grid item>
                        <Typography variant={"h6"} gutterBottom>Team members</Typography>
                    </Grid>
                </Grid>
                <Grid container justify={"flex-start"} alignItems={"center"} spacing={1}>
                    {submissionData?.submission?.participants.map((p) => {
                        return (
                            <Grid item>
                                <Chip label={p?.name} color="primary"/>
                            </Grid>
                        )
                    })}
                </Grid>
                <br/>
                <Grid container item justify={"center"}>
                    <Button size="large"
                            variant="contained"
                            className={classes.margin_1}
                            color="secondary"
                            href={workshopData?.workshopUnit?.url as string}
                            target={"_blank"}
                    >
                        Go to the activity&nbsp;<ArrowForward/>&nbsp;{workshopData?.workshopUnit?.title}
                    </Button>
                </Grid>
            </Grid>
            <br/>
            <Grid container alignItems={"flex-start"} justify={"flex-start"} spacing={4}>
                <Grid item key={0} xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>
                        What has been well? What has been hard?
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder="Type your text here..."
                        multiline
                        fullWidth
                        rows={10}
                        variant="outlined"
                        value={submissionData?.submission?.reflection}
                        // label={"Read only"}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item key={1} xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>
                        Do you have any questions?
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder="Type your question here..."
                        multiline
                        fullWidth
                        rows={10}
                        variant="outlined"
                        value={submissionData?.submission?.questions}
                        // label={"Read only"}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item key={3} xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>
                        Can you demonstrate your work?
                    </Typography>
                    
                    <div className="text-left text-lg">
                        <a target="_blank" href={submissionData?.submission?.link || "#"}>{submissionData?.submission?.link}</a>
                    </div>
                </Grid>
                <Grid item key={4} xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>
                        Read your teacher's feedback
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder="None"
                        multiline
                        fullWidth
                        rows={10}
                        variant="outlined"
                        value={submissionData?.submission?.feedback}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default SubmittedWorkshop