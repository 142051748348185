import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import XCalendar from "../components/XCalendar";
import EditableWorkshopInfo from "../components/EditableWorkshopInfo";
import WorkshopInfo from "../components/WorkshopInfo";
import Participants from "../components/Participants";
import {
    Event,
    EventInput,
    Mutation,
    MutationUpdateWorkshopArgs,
    Participant,
    ParticipantInput,
    Query,
    QueryWorkshopArgs
} from "../graphql/generated/graphql-main";
import {useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import {mainGqlTemplates} from "../utils/gql-client";
import {IWorkshop} from "../types";
import {WorkshopContext} from "./LaunchWorkshop";
import {Redirect} from "react-router-dom";
import {useAuth} from "../components/auth/AuthProvider";
import XSnackbar from "../components/XSnackbar";
import {CustomWaiting} from "../components/CustomWaiting";
import {stripTypename} from "../utils";

const CalendarView = () => {
    const auth = useAuth()
    const {workshop} = useParams<{ workshop: string }>();

    const [edit, setEdit] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<string>("")
    const [endDate, setEndDate] = useState<string>("")
    const [launchCode, setLaunchCode] = useState<string>("")
    const [workshopId, setWorkshopId] = useState<string>("")
    const [workshopName, setWorkshopName] = useState<string>("")
    const [username, setUsername] = useState<string>("")
    const [participants, setParticipants] = useState<Participant[]>([])
    const [events, setEvents] = useState<Event[]>([])
    const [successSnackbar, setSuccessSnackbar] = useState<boolean>(false)
    const [errorSnackbar, setErrorSnackbar] = useState<boolean>(false)


    const {data, loading} = useQuery<Query, QueryWorkshopArgs>(mainGqlTemplates.GET_WORKSHOP, {
        variables: {launch_code: workshop},
        fetchPolicy: "cache-and-network"
    });

    const [updateWorkshop] = useMutation<Mutation, MutationUpdateWorkshopArgs>(mainGqlTemplates.UPDATE_WORKSHOP)

    useEffect(() => {
        if (data?.workshop) {
            const {
                name,
                workshop_id,
                username,
                start_date,
                end_date,
                launch_code,
                participants,
                schedule
            } = data?.workshop
            setWorkshopName(name || "")
            setWorkshopId(workshop_id || "")
            setUsername(username || "")
            setStartDate(start_date || "")
            setEndDate(end_date || "")
            setLaunchCode(launch_code || "")
            setParticipants(participants as Participant[] || [])
            setEvents(schedule as Event[] || [])
        }
    }, [data])

    const update = async () => {
        // https://github.com/apollographql/react-apollo/issues/741
        const participantArr: ParticipantInput[] = [];
        participants.forEach((p) => {
            participantArr.push(stripTypename(p))
        })
        const eventArr: EventInput[] = []
        events.forEach((e) => {
            eventArr.push(stripTypename(e))
        })
        const res = await updateWorkshop({
            variables: {
                workshopInput: {
                    username: username,
                    workshop_id: workshopId,
                    launch_code: launchCode,
                    start_date: startDate,
                    end_date: endDate,
                    name: workshopName,
                    participants: participantArr,
                    schedule: eventArr
                }
            }
        })
        if (res?.data?.updateWorkshop) {
            setSuccessSnackbar(true)
        } else {
            setErrorSnackbar(true)
        }
    }

    const xWorkshop: IWorkshop = {
        username: username,
        workshopId: workshopId,
        startDate: startDate,
        endDate: endDate,
        workshopName: workshopName,
        launchCode: launchCode,
        participants: participants,
        events: events,
        setUsername: setUsername,
        setWorkshopId: setWorkshopId,
        setStartDate: setStartDate,
        setEndDate: setEndDate,
        setWorkshopName: setWorkshopName,
        setParticipants: setParticipants,
        setEvents: setEvents,
        onUpdate: update
    }

    if (!auth.isAuthenticated) {
        return <Redirect to={"/"}/>
    }

    if (loading)
        return <CustomWaiting/>

    return (
        <WorkshopContext.Provider value={xWorkshop}>
            <Typography align="left" variant="h4" component="h4" gutterBottom>
                Calendar View&nbsp;

                {edit && (
                    <Button
                        className="x-btn-edit"
                        onClick={() => {
                            update().then()
                            setEdit(false)
                        }}
                    >
                        <SaveIcon fontSize="default"/>
                        <span className="ml-5 text-sm normal-case">
                            Click on the disk to save your changes
                        </span>
                    </Button>
                )}

                {!edit && (
                    <Button
                        onClick={() => {
                            setEdit(true)
                        }}
                    >
                        <EditIcon fontSize="default"/>
                        <span className="ml-5 text-sm normal-case">
                            Click on the pencil to edit your workshop's info and participants
                        </span>
                    </Button>
                )}
            </Typography>

            {edit
                ? <EditableWorkshopInfo/>
                : <WorkshopInfo/>}

            <br/>
            <Participants edit={edit}/>
            <br/>
            <br/>

            <Typography className={"text-sm"} align={"left"} variant={"h4"} component={"h4"}>
                Click on a date box below to select a unit to work on.<br/>
                You can review the content of a unit once it is displayed in a date box.
            </Typography>

            <XCalendar/>


            <XSnackbar snackbar={successSnackbar}
                       setSnackbar={setSuccessSnackbar}
                       message={"The workshop has been successfully updated!"}
                       severity={"success"}
            />
            <XSnackbar snackbar={errorSnackbar}
                       setSnackbar={setErrorSnackbar}
                       message={"The workshop cannot be updated!"}
                       severity={"error"}
            />
        </WorkshopContext.Provider>
    )
}

export default CalendarView