import * as React from 'react';
import {useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    createStyles,
    Fab,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    MenuItem,
    Select,
    TextField,
    Theme,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import UploadButton from "../components/UploadButton";
import DeleteIcon from "@material-ui/icons/Delete"
import {useParams} from "react-router";
import {mainGqlTemplates} from "../utils/gql-client";
import {useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationUpdateSubmissionArgs,
    ParticipantInput,
    Query,
    QueryWorkshopUnitArgs
} from "../graphql/generated/graphql-main";
import dayjs from "dayjs";
import {ArrowForward, Send} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import SubmitDialog from "../components/dialog/SubmitDialog";
import XSnackbar from "../components/XSnackbar";
import {CustomWaiting} from "../components/CustomWaiting";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 400,
            height: 40
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            marginRight: theme.spacing(1),
        },
        margin_1: {
            margin: theme.spacing(1),
        },
        video: {
            width: "100%",
            height: 360
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        }
    }),
);

const MyWorkshop = () => {
    const classes = useStyles()

    const history = useHistory()

    const {
        workshop: workshopParam,
        unit: unitParam,
        eventId: eventIdParam
    } = useParams<{ workshop: string, unit: string, eventId: string }>()
    const [submitDialog, setSubmitDialog] = useState<boolean>(false)
    const [reflection, setReflection] = useState<string>("")
    const [questions, setQuestions] = useState<string>("")
    const [link, setLink] = useState<string>("")
    const [participants, setParticipants] = useState<string[]>([])
    const [successSnackbar, setSuccessSnackbar] = useState<boolean>(false)
    const [errorSnackbar, setErrorSnackbar] = useState<boolean>(false)

    const {data, loading} = useQuery<Query, QueryWorkshopUnitArgs>(mainGqlTemplates.WORKSHOP_UNIT, {
        variables: {
            launch_code: workshopParam,
            unit_num: Number(unitParam),
            event_id: eventIdParam
        },
        fetchPolicy: "no-cache"
    })

    const [updateSubmission] = useMutation<Mutation, MutationUpdateSubmissionArgs>(mainGqlTemplates.UPDATE_SUBMISSION)

    const getParticipantName = (id: string) => {
        return data?.workshopUnit?.participants?.filter(participant => participant?.participant_id === id)[0]?.name as string
    }

    const getParticipantsFromIds = (ids: string[]) => {
        let participantArr = data?.workshopUnit?.participants?.filter(p => ids.includes(p?.participant_id as string))
        return participantArr?.map(p => {
            return (
                {participant_id: p?.participant_id, name: p?.name}
            )
        })
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const onSubmit = async () => {
        const res = await updateSubmission({
            variables: {
                submissionInput: {
                    launch_code: workshopParam,
                    unit: Number(unitParam),
                    event_id: eventIdParam,
                    participants: getParticipantsFromIds(participants) as ParticipantInput[],
                    reflection: reflection,
                    questions: questions,
                    link: link
                }
            }
        })
        res?.data?.updateSubmission ? setSuccessSnackbar(true) : setErrorSnackbar(true)
        // Reset the form
        setParticipants([])
        setReflection("")
        setQuestions("")
        setLink("")
    }

    if (data && !data?.workshopUnit)
        history.push("/")

    if (loading)
        return <CustomWaiting/>

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid container item>
                    <Typography align={"left"} variant="h4" component={"h1"}>
                        {data?.workshopUnit?.workshop_name}
                    </Typography>
                </Grid>
                <Grid container item>
                    <Chip className={classes.chip} color={"secondary"} label={`Unit ${data?.workshopUnit?.num}`}/>
                    <Chip className={classes.chip} color={"secondary"}
                          label={dayjs(data?.workshopUnit?.start_date as string).format("MMMM D, YYYY")}/>
                </Grid>
                <Grid container alignItems={"center"} justify={"flex-start"}>
                    <Grid item>
                        <Typography align={"left"} variant={"h6"}>Who is in your team?</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                multiple
                                labelId="participants-label"
                                id="participants-select-outlined"
                                value={participants}
                                onChange={(e) => {
                                    setParticipants(e.target.value as string[]);
                                }}
                                label="Participant(s)"
                                input={<Input id="select-multiple-chip"/>}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {(selected as string[]).map((value) => (
                                            <Chip key={value} label={getParticipantName(value)}
                                                  className={classes.chip}/>
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                                error={participants.length === 0}
                            >
                                {data?.workshopUnit?.participants?.map(participant => {
                                    return (
                                        <MenuItem key={participant?.participant_id} value={participant?.participant_id}>
                                            {participant?.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText error={participants.length === 0} id="participants-label">
                                This field is required.
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <br/>
                <Grid container item justify={"center"}>
                    <Button size="large"
                            variant="contained"
                            className={classes.margin_1}
                            color="secondary"
                            href={data?.workshopUnit?.url as string}
                            target={"_blank"}
                    >
                        Go to the activity&nbsp;<ArrowForward/>&nbsp;{data?.workshopUnit?.title}
                    </Button>
                </Grid>
            </Grid>
            <br/>
            <Grid container alignItems={"flex-start"} justify={"flex-start"} spacing={4}>
                <Grid item key={0} xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>
                        What has been well? What has been hard?
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder="Type your text here..."
                        multiline
                        fullWidth
                        rows={10}
                        variant="outlined"
                        value={reflection}
                        onChange={(e) => {
                            setReflection(e.target.value)
                        }}
                    />
                </Grid>
                <Grid item key={1} xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>
                        Do you have any questions?
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder="Type your question here..."
                        multiline
                        fullWidth
                        rows={10}
                        variant="outlined"
                        value={questions}
                        onChange={(e) => {
                            setQuestions(e.target.value)
                        }}
                    />
                </Grid>
                <Grid item key={2} xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>
                        Can you demonstrate your work?
                    </Typography>

                    <TextField
                        id="outlined-textarea"
                        placeholder="Enter a link here ..."
                        fullWidth
                        rows={10}
                        variant="outlined"
                        value={link}
                        onChange={(e) => {
                            setLink(e.target.value)
                        }}
                    />
                </Grid>
            </Grid>

            <Fab
                disabled={!(reflection && participants.length > 0)}
                variant="extended"
                color={"secondary"}
                className={classes.fab}
                onClick={() => {
                    setSubmitDialog(true)
                }}
            >
                We are done!&nbsp;<Send/>
            </Fab>

            {submitDialog && (
                <SubmitDialog
                    onSubmit={onSubmit}
                    setOpen={setSubmitDialog}
                    open={submitDialog}
                />
            )}

            <XSnackbar
                snackbar={successSnackbar}
                setSnackbar={setSuccessSnackbar}
                message={"Thanks for submitting your work."}
                severity={"success"}
            />
            <XSnackbar
                snackbar={errorSnackbar}
                setSnackbar={setErrorSnackbar}
                message={"The system encountered some problem. Please inform your supervisor immediately."}
                severity={"error"}
            />
        </React.Fragment>
    )
}

export default MyWorkshop