import * as React from 'react';
import {CircularProgress} from "@material-ui/core";
import {CSSProperties} from "react";
import clsx from "clsx";


interface XCustomWaitingProps extends React.HTMLAttributes<any> {
    mainClassName?: string
    msgClassName?: string
    spinnerClassName?: string
    spinnerInlineStyle?: CSSProperties
    waitingMsg?: JSX.Element
}
export const CustomWaiting = (props: XCustomWaitingProps) => {
    return (
        <div className={clsx(props.mainClassName, "text-center x-custom-spinner")}>
            <div className={clsx(props.msgClassName)}>
                {props.waitingMsg}
            </div>
            <CircularProgress
                className={clsx(props.spinnerClassName, "")}
                style={props.spinnerInlineStyle || {
                    width: 40,
                    height: 40
                }}
            />
        </div>
    )
}