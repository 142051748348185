import {createSlice} from '@reduxjs/toolkit'
import {MenuState} from "./type";


const initialState: MenuState = {
    connectedAsParticipant: false,
    activeMenuItem: "",
    openMenuItems: ["my-workshops"]
}

export const menuSlice = createSlice({
    name: 'menuSlice',
    initialState: initialState,
    reducers: {
        connectAsParticipant: (s) => {s.connectedAsParticipant = true},
        setActiveMenuItem: (s, a) => {s.activeMenuItem = a.payload},
        setOpenMenuItems: (s, a) => {s.openMenuItems = a.payload},
    }
})

export const menuActions = menuSlice.actions