import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import * as React from "react";


interface XSnackbarProps {
    snackbar: boolean
    setSnackbar: (arg: boolean) => void
    message: string
    severity: "success"|"error"
}

const XSnackbar = (props: XSnackbarProps) => {

    const onClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        props.setSnackbar(false);
    };

    return (
        <Snackbar open={props.snackbar} autoHideDuration={6000} onClose={onClose}>
            <Alert severity={props.severity} onClose={onClose}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

export default XSnackbar