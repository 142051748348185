import * as React from 'react';
import {Button, Chip, createStyles, Fab, Grid, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import '../assets/css/video-react.css';
import {useParams} from "react-router";
import {ArrowForward, People, Send} from "@material-ui/icons";
import {useEffect, useState} from "react";
import {mainGqlTemplates} from "../utils/gql-client";
import {useMutation, useQuery} from "@apollo/client";
import {
    Mutation, MutationUpdateSubmissionArgs, Participant, ParticipantInput,
    Query,
    QuerySubmissionArgs,
    QueryUnitArgs
} from "../graphql/generated/graphql-main";
import dayjs from "dayjs";
import {Redirect} from "react-router-dom";
import {useAuth} from "../components/auth/AuthProvider";
import XSnackbar from "../components/XSnackbar";
import {CustomWaiting} from "../components/CustomWaiting";
import {dateTimeFormat} from "../utils";


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        chip: {
            marginRight: theme.spacing(1),
        },
        margin_1: {
            margin: theme.spacing(1),
        },
        video: {
            width: "100%",
            height: "90%"
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        }
    }),
);

const EvaluationView = () => {
    const classes = useStyles();
    const auth = useAuth();

    const {submission:submissionId, group:groupId} = useParams<{submission:string, group:string}>();

    const [teacherFeedback, setTeacherFeedback] = useState<string>("")
    const [successSnackbar, setSuccessSnackbar] = useState<boolean>(false)
    const [errorSnackbar, setErrorSnackbar] = useState<boolean>(false)

    const {data, loading} = useQuery<Query, QuerySubmissionArgs>(mainGqlTemplates.GET_SUBMISSION, {
        variables: {
            submission_id: submissionId
        },
    })

    const {data:unitData, loading:unitLoading} = useQuery<Query, QueryUnitArgs>(mainGqlTemplates.GET_UNIT, {
        variables: {
            num: data?.submission?.unit || 0
        }
    })

    const [updateSubmission] = useMutation<Mutation, MutationUpdateSubmissionArgs>(mainGqlTemplates.UPDATE_SUBMISSION)

    useEffect(() => {
        setTeacherFeedback(data?.submission?.feedback || "")
    }, [data])

    const onSubmit = async () => {
        const participantArr: ParticipantInput[] = [];
        (data?.submission?.participants as Participant[]).forEach((p) => {
            participantArr.push({participant_id: p.participant_id, name: p.name})
        })
        const res = await updateSubmission({
            variables: {
                submissionInput: {
                    id: data?.submission?.id,
                    launch_code: data?.submission?.launch_code as string,
                    unit: data?.submission?.unit as number,
                    event_id: data?.submission?.event_id as string,
                    participants: participantArr,
                    reflection: data?.submission?.reflection,
                    questions: data?.submission?.questions,
                    link: data?.submission?.link,
                    evaluated: true,
                    feedback: teacherFeedback
                }
            }
        })
        if (res?.data?.updateSubmission) {
            setSuccessSnackbar(true)
        } else {
            setErrorSnackbar(true)
        }
    }

    if (!auth.isAuthenticated)
        return <Redirect to={"/"}/>

    if (loading || unitLoading)
        return <CustomWaiting/>

    return (
        <React.Fragment>
            <Typography align={"left"} variant={"h4"} component={"h4"} gutterBottom>Evaluations</Typography>
            <Grid container justify={"space-between"}>
                <Grid item>
                    <Chip className={classes.chip} color={"secondary"} label={`Unit ${data?.submission?.unit}`}/>
                    <Chip className={classes.chip} color={"secondary"} label={`G${groupId}`}/>
                    <Chip className={classes.chip} color={"secondary"}
                          label={dayjs(data?.submission?.date as string).format(dateTimeFormat)}
                    />
                </Grid>
                <Grid item>
                    <Button size="large"
                            variant="contained"
                            color="secondary"
                            href={unitData?.unit?.url as string}
                            target={"_blank"}
                    >
                        Go to the activity&nbsp;<ArrowForward/>&nbsp;{unitData?.unit?.title}
                    </Button>
                </Grid>
            </Grid>
            <Grid container justify={"flex-start"} alignItems={"center"} spacing={1}>
                <Grid item>
                    <People color="primary" fontSize={"large"}/>
                </Grid>
                <Grid item>
                    <Typography variant={"h6"}>Participants</Typography>
                </Grid>
            </Grid>
            <Grid container justify={"flex-start"} alignItems={"center"} spacing={1}>
                {data?.submission?.participants.map((p) => {
                    return (
                        <Grid item key={`evaluationView-participant-${p?.participant_id}`}>
                            <Chip label={p?.name} color="primary" />
                        </Grid>
                    )
                })}
            </Grid>
            <br/>
            <br/>
            <Grid container spacing={6}>
                <Grid item key={0} xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>Self-reflection</Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder="None"
                        multiline
                        fullWidth
                        rows={10}
                        variant="outlined"
                        value={data?.submission?.reflection}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid key={1} item xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>Questions</Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder="None"
                        multiline
                        fullWidth
                        rows={10}
                        variant="outlined"
                        value={data?.submission?.questions}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid key={2} item xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>Video evidence</Typography>

                    <div className="text-left text-lg">
                        <a target="_blank" href={data?.submission?.link || "#"}>{data?.submission?.link}</a>
                    </div>
                </Grid>
                <Grid item key={3} xs={12} md={6}>
                    <Typography align={"left"} variant={"h5"} gutterBottom>Teacher's feedback</Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder="Comments / Evaluation / Answers"
                        multiline
                        fullWidth
                        rows={20}
                        variant="outlined"
                        value={teacherFeedback}
                        onChange={(e) => {setTeacherFeedback(e.target.value)}}
                    />
                </Grid>
            </Grid>
            <Fab variant="extended" color={"secondary"} className={classes.fab} onClick={onSubmit}>
                Submit feedback&nbsp;<Send/>
            </Fab>
            <XSnackbar snackbar={successSnackbar}
                       setSnackbar={setSuccessSnackbar}
                       message={"The feedback has been successfully submitted."}
                       severity={"success"}
            />
            <XSnackbar snackbar={errorSnackbar}
                       setSnackbar={setErrorSnackbar}
                       message={"The system encountered some problem. The feedback has not been submitted."}
                       severity={"error"}
            />
        </React.Fragment>
    )
}

export default EvaluationView