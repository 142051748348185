import {Grid, Typography} from "@material-ui/core";
import * as React from "react";
import dayjs from "dayjs";
import {useContext} from "react";
import {WorkshopContext} from "../views/LaunchWorkshop";


const DATE_FORMAT = "MMM DD, YYYY"

const WorkshopInfo = () => {
    const workshopCtx = useContext(WorkshopContext)

    return (
        <Grid container justify={"flex-start"}>
            <Grid item xs={12} md={10}>
                <Typography align={"left"} variant={"body1"}>
                    <b>Workshop name:</b> {workshopCtx?.workshopName || "PLEASE TYPE A WORKSHOP NAME"}
                </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
                <Typography align={"left"} variant={"body1"}><b>Workshop dates:</b> {
                    (workshopCtx?.startDate && workshopCtx?.endDate)
                        ? `From ${dayjs(workshopCtx.startDate).format(DATE_FORMAT)}, to ${dayjs(workshopCtx.endDate).format(DATE_FORMAT)}`
                        : "PLEASE SELECT WORKSHOP DATES"
                }</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
                <Typography align={"left"} variant={"body1"}>
                    <b>Workshop launchcode:</b> {workshopCtx?.launchCode || ""}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default WorkshopInfo