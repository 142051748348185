import React, {HTMLAttributes} from "react"
import {DialogContentText} from "@material-ui/core"
import {LaunchIcon} from "../../views/LaunchHome";
import ClosableDialog from "./ClosableDialog";
import {ButtonAction} from "../../types";

interface InvalidEventDialogProps extends HTMLAttributes<any> {
    open: boolean
    setOpen: (arg: boolean) => void
    scheduleData: any
}

const InvalidEventDialog = (props: InvalidEventDialogProps) => {

    const actions: ButtonAction[] = [
        {
            name: "OK",
            action: () => props.setOpen(false),
            color: "primary",
            variant: "contained"
        }
    ]

    return (
        <ClosableDialog
            open={props.open}
            setOpen={props.setOpen}
            icon={<LaunchIcon/>}
            title={"LAUNCH"}
            actions={actions}
        >
            <React.Fragment>
                <DialogContentText>
                    The selected date cannot be outside of the workshop's start and end dates. <br/><br/>
                    To assign a unit to this date, you can modify the workshop's start and end dates to include
                    this date by clicking on the edit pencil above.
                </DialogContentText>
            </React.Fragment>
        </ClosableDialog>
    )
}

export default InvalidEventDialog