import * as React from 'react';
import {
    Avatar,
    Chip,
    createStyles,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Typography
} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {InsertDriveFile} from "@material-ui/icons";
import {mainGqlTemplates} from "../utils/gql-client";
import {Query, QuerySubmissionsByUnitArgs, QueryWorkshopUnitArgs} from "../graphql/generated/graphql-main";
import {useQuery} from "@apollo/client";
import dayjs from "dayjs";
import {Link, useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {CustomWaiting} from "../components/CustomWaiting";
import {dateTimeFormat} from "../utils";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            minWidth: 360,
        },
        chip: {
            marginRight: theme.spacing(1),
        },
    }),
);

const WorkshopSubmissions = () => {
    const classes = useStyles()
    const history = useHistory()
    const {
        workshop: workshopParam,
        unit: unitParam,
        eventId: eventIdParam
    } = useParams<{ workshop: string, unit: string, eventId: string }>()

    const {data, loading} = useQuery<Query, QuerySubmissionsByUnitArgs>(mainGqlTemplates.GET_SUBMISSIONS_BY_UNIT, {
        variables: {
            launch_code: workshopParam,
            event_id: eventIdParam
        }
    })

    const {
        data: workshopData,
        loading: workshopLoading
    } = useQuery<Query, QueryWorkshopUnitArgs>(mainGqlTemplates.WORKSHOP_UNIT, {
        variables: {
            launch_code: workshopParam,
            unit_num: Number(unitParam),
            event_id: eventIdParam
        },
        fetchPolicy: "no-cache"
    })
    if (workshopData && !workshopData?.workshopUnit)
        history.push("/")

    if (loading || workshopLoading)
        return <CustomWaiting/>

    return (
        <React.Fragment>
            <Typography variant={"h4"} component={"h1"} align={"left"}
                        gutterBottom>{workshopData?.workshopUnit?.workshop_name}</Typography>
            <Grid container>
                <Chip className={classes.chip} color={"secondary"} label={`Unit ${workshopData?.workshopUnit?.num}`}/>
                <Chip className={classes.chip}
                      color={"secondary"}
                      label={
                          dayjs(workshopData?.workshopUnit?.start_date as string)
                              .format(dateTimeFormat.substr(0, 12))}
                />
            </Grid>
            <br/>
            <Typography component={"h3"} align={"left"}>This unit has been closed by the organizer. Therefore, submissions are no longer possible. Please feel free to take a look at the team's work below. Click on a team to view the details.</Typography>
            <br/>
            <Paper className={classes.root}>
                <List>
                    {data?.submissionsByUnit?.length === 0 &&
                    <ListItem>
                        <ListItemText primary={"No submission yet"} className={"italic text-gray-600"}/>
                    </ListItem>
                    }
                    {data?.submissionsByUnit?.map((s) => {
                        return (
                            <ListItem key={`workshop-submission-${s?.id}`} button component={Link}
                                      to={`/submitted-workshop/${s?.launch_code}/${s?.id}`}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <InsertDriveFile/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={s?.participants.map(p => p?.name).join(", ")}
                                              secondary={dayjs(s?.date as string).format(dateTimeFormat)}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Paper>
        </React.Fragment>
    )
}

export default WorkshopSubmissions