import * as React from 'react';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    createStyles,
    Grid,
    Typography
} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import image from '../assets/img/screenshot.png'
// import image from '../assets/img/robot.png'
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            // maxWidth: 345,
            textAlign: "left"
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        avatar: {
            backgroundColor: red[500],
        },
    }),
);

interface ICard {
    stepNum: number
    title: string
    instructions: string
    image: string
}

const InstructionCard = (props: ICard) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <Card className={classes.root}>
                <CardHeader
                    avatar={
                        <Avatar className={classes.avatar}>
                            {props.stepNum}
                        </Avatar>
                    }
                    title={
                        <Typography variant={"h6"}>{props.title}</Typography>
                    }
                />
                {/*<CardMedia*/}
                {/*    className={classes.media}*/}
                {/*    image={props.image}*/}
                {/*    title={props.title}*/}
                {/*/>*/}
                {/*<CardContent>*/}
                {/*    <Typography variant="body2" color="textSecondary" component="p">*/}
                {/*        {props.instructions}*/}
                {/*    </Typography>*/}
                {/*</CardContent>*/}
            </Card>
        </React.Fragment>
    )
}

const HowItWorks = () => {
    const organizerData:ICard[] = [
        {
            stepNum: 1,
            title: "Create an account",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 2,
            title: "Launch a workshop",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 3,
            title: "Prepare your workshop",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 4,
            title: "Run your workshop",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 5,
            title: "Evaluate participants' work",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 6,
            title: "Share your reflections",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
    ]

    const participantData:ICard[] = [
        {
            stepNum: 1,
            title: "Get to the launch site",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 2,
            title: "Enter your launchcode",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 3,
            title: "Identify your team",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 4,
            title: "Click and go to your tutorial",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 5,
            title: "Share your work and reflections",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
        {
            stepNum: 6,
            title: "Read the organizer evaluation",
            image: image,
            instructions: "An easy process with simple steps. Enter a username, select a password, provide the name of your institution, and that’s it!"
        },
    ]

    return (
        <React.Fragment>
            <Typography variant={"h4"} component={"h1"} align={"left"}>Organizers' Journey</Typography>
            <Typography variant={"body1"} align={"left"} gutterBottom>A roadmap for junior coding workshops</Typography>
            <br/>
            <Grid container spacing={6}>
                {organizerData.map((item, i) => {
                    return (
                        <Grid key={`organizer-step-${i}`} item md={4}>
                            <InstructionCard
                                stepNum={item.stepNum}
                                title={item.title}
                                image={item.image}
                                instructions={item.instructions}
                            />
                        </Grid>
                    )
                })}
            </Grid>
            <br/>
            <br/>
            <br/>
            <Typography variant={"h4"} component={"h1"} align={"left"}>Participants' adventure</Typography>
            <Typography variant={"body1"} align={"left"} gutterBottom>A take-off for the next generation of programmers</Typography>
            <br/>
            <Grid container spacing={6}>
                {participantData.map((item, i)=> {
                    return (
                        <Grid key={`participant-step-${i}`} item md={4}>
                            <InstructionCard
                                stepNum={item.stepNum}
                                title={item.title}
                                image={item.image}
                                instructions={item.instructions}
                            />
                        </Grid>
                    )
                })}

            </Grid>
        </React.Fragment>
    )
}

export default HowItWorks