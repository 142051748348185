import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import React from "react";
import {useQuery} from "@apollo/client";
import {Query, Unit} from "../graphql/generated/graphql-main";
import {mainGqlTemplates} from "../utils/gql-client";
import {CustomWaiting} from "./CustomWaiting";
import {zeroUnit} from "../utils";


interface UnitSelectProps {
    selectedUnit: Unit
    setSelectedUnit: (arg:Unit) => void
}

export const UnitSelect = (props: UnitSelectProps) => {
    const {data, loading} = useQuery<Query, Unit[]>(mainGqlTemplates.GET_UNITS)

    if (loading)
        return <CustomWaiting/>

    return (
        <React.Fragment>
            <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={props.selectedUnit?.num || 0}
                    onChange={(e) => {
                        if (data) {
                            const results = data?.units?.filter(unit => unit.num === (e.target.value as number))
                            props.setSelectedUnit(results.length!==0 ? results[0] : zeroUnit)
                        }
                    }}
                    label="Unit"
                >
                    {data?.units?.map((unit) => {
                        return (
                            <MenuItem key={unit.num} value={unit.num}>Unit {unit.num}: {unit.title}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </React.Fragment>
    )
}