import * as React from 'react';
import {HTMLAttributes} from 'react';
import {CssBaseline} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import clsx from 'clsx';
import {useAuth} from "../auth/AuthProvider";
import {drawerWidth, Navigation} from './Navigation';
import {useRouteMatch} from "react-router-dom";
import { isParticipantPage } from '../../utils';


interface XProps extends HTMLAttributes<any> {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(10),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    })
)

/**
 * Standard layout for app pages w/ navigation (NOT used for login page and others that have no menus)
 * @param props
 * @constructor
 */
export const StandardLayout = (props: XProps) => {
    const auth = useAuth()
    const classes = useStyles()
    const thePath = useRouteMatch().url

    return (
        <div className="App flex">
            {/* Apply CSS Baseline (some normalizing) to all sub-components */}
            <CssBaseline/>

            {/* Left & top navigations components */}
            <Navigation/>

            {/* Main body components (router-based) */}
            <main className={clsx(classes.content, {
                [classes.contentShift]: auth.isAuthenticated || isParticipantPage(thePath),
            })}>
                { props.children }
            </main>

            {/* Footer at the bottom of page */}
            {/*<div className="">*/}
            {/*    <span>*/}
            {/*        LAUNCH&nbsp;{new Date().getFullYear()}&nbsp;-&nbsp;All rights reserved*/}
            {/*    </span>*/}
            {/*</div>*/}
        </div>
    )
}


